import React, { useContext, useEffect, useState } from 'react';
import { NavLink as RRNavLink, withRouter, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Row, CardHeader } from 'styled/common';
import { Alert } from 'reactstrap';
import Layout from '../components/Layout';
import { PersonsContext, PersonsProvider } from 'contexts';
import styled from "styled-components";
import { Bar } from 'react-chartjs-2';
import { fetchPersonsMonthHourList } from '../contexts/fetchAction';
import { isTemplateExpression } from 'typescript';

const Col = styled.div.attrs(() => ({
  className: 'col-lg col-lg-2 mb-3',
}))`  
`;

const Col4 = styled.div.attrs(() => ({
  className: 'col-lg col-lg-4',
}))`
`;

const Col3 = styled.div.attrs(() => ({
  className: 'col-lg col-lg-3',
}))`
`;

const Col4B = styled.div.attrs(() => ({
  className: 'col-lg col-lg-4',
}))`
  background-color: #06074d;  
`;

const options = {
  responsive: true,
  tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
  },
  legend: {
      labels: {
          fontColor: 'rgb(192,192,192)'
      },
  },
  cutoutPercentage: 70,
}

// const labels = ['1月', '2月', '3月', '4月', '5月', '6月','7月','8月'];
// const data = [113, 99, 132, 154, 120, 168, 123, 110];

// const data2 = {
//   labels: labels,
//   datasets: [
//     {
//       label: '每月待處理工時',
//       data: data,
//       backgroundColor: [
//         'rgba(54, 162, 235, 0.5)',
//         'rgba(54, 162, 235, 0.5)',
//         'rgba(54, 162, 235, 0.5)',
//         'rgba(54, 162, 235, 0.5)',
//         'rgba(54, 162, 235, 0.5)',
//         'rgba(54, 162, 235, 0.5)',
//         'rgba(54, 162, 235, 0.5)',
//         'rgba(54, 162, 235, 0.5)',
//       ],
//         borderColor: [
//         'rgba(54, 162, 235, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(54, 162, 235, 1)',
//       ],
//       borderWidth: 1,
//     },
//   ],
// }

const PersonBar = (props:any) => {
  const {
     data
  } = props;

  const [ labels, setLabels] = useState([]);
  const [ hour, setHour] = useState([])
  const [ backgroundColor, setBackgroundColor] = useState([])
  const [ borderColor, setBorderColor] = useState([])
 
  useEffect( () => {
    setLabels(data.map(function(item:any) { return item.month}));
    setHour(data.map(function(item:any) { return item.hour}));
    
    setBackgroundColor(data.map(function(item:any) { return 'rgba(54, 162, 235, 0.5)'}));
    setBorderColor(data.map(function(item:any) { return 'rgba(54, 162, 235, 1)'}));
  },[]);

  const data2 = {
    labels: labels,
    datasets: [
      {
        label: '每月待處理工時',
        data: hour,
        backgroundColor: backgroundColor,
          borderColor: borderColor,
        borderWidth: 1,
      },
    ],
  }
  return(
    <Bar options={options} data={data2} /> 
  )
   
} 

//人員每月工時清單
const PeronsMonthList = () => {
  const { state, dispatch } = useContext(PersonsContext);
  const { monthList } = state;

  useEffect( () => {
    //取得人員每月工時與案件工時
    const getPersonsMonthHourList = async () => {
       const result = await fetchPersonsMonthHourList();
      //  console.log(result);
       if(result.status === 200) {
          dispatch({ type: "GET_PERSONS_MONTH_LIST", payload: result.data });
          // dispatch({ type: "GET_DEVICE_TOTAL", payload: result.total });
       }
    }
    getPersonsMonthHourList();

  },[dispatch]);

  return(
    <Row>
     { monthList.map((i:any) =>
      <Col4 key={i.id}>
          <h4>{i.name}</h4>
          <PersonBar data = {i.list}/>
        <Alert color="info">已接案 未評估工時件數： {i.toDoTotal} 件</Alert>
        <Alert color="success">案件已評估 待處理工時：{i.waitHour} 小時</Alert>  
      </Col4>
   )}
    </Row> 
  )
}

const Persons = () => {
  const { t } = useTranslation();
  return (    
    <PersonsProvider>
    <Layout>
      <h2>{t("persons.title")}</h2>
      <hr />
      <PeronsMonthList />    
    </Layout>
    </PersonsProvider>
  )
}

export default withRouter(Persons);

