type ActionTypes =
  | { type: 'GET_ERP_NOTIFY_LIST', payload: any}
  | { type: 'GET_ERP_LOG_LIST', payload: any }
  | { type: 'SET_ERP_COUNT', payload: any }

// type ErpLogType = {
//     APPServer: string;
//     ComputerPC: string;
//     EmployeeID: string;
//     EmployeeName: string;
//     IP: string;
//     Identify: string;
//     IsRead: number;
//     JobStatus: number;
//     LoginOutDateTime: string;
//     OrganizationUnitName: string;
//     Status: string;
//     functionName: string;
//     unitId: string;
//     userId: string;
// }

//   type ErpNotifyType = {
//     ID: any;
//     DEPARTMENT: string;
//     DEVICE_NAME: string;
//     EMPLOYEE_ID: string;
//     EMPLOYEE_NAME: string;
//     IS_NOTIFY: number;
//     NOTIFY_CONTENT: string;
//     ONLINE_TIME: string;
//     NOTIFY_TIME: string;
//     TYPE: string;
//   }
 
  type InitialErpStateType = {
    logList: any;
    notifyList: any;
    count: number;
  }



/* ERP Page Reducer */
export const ErpReducer = (state: InitialErpStateType , action: ActionTypes) => {
    console.log(action);
    switch (action.type) {
        case 'GET_ERP_NOTIFY_LIST':
            return {...state, notifyList: action.payload };
        case 'GET_ERP_LOG_LIST':    
            return {...state, logList: action.payload };
        case 'SET_ERP_COUNT':    
            return {...state, count: action.payload };
        default:
            return state;
    }
}
