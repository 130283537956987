type ActionTypes =
  | { type: 'GET_DEVICE_LIST', payload: any  }
  | { type: 'GET_DEVICE_TOTAL', payload: number}
  | { type: 'GET_DEPARTMENT_LIST', payload: any }

  interface DeviceType {
    id: number,
    computer_name: string,
    owner_id: string,
    owner_name: string,
    department_id: string,
    department_name: string,
    status: number,
    create_date: string,
    remark: string
  }
  
type InitialStateType = {
    deviceList: DeviceType[];
    departmentList: any;
    total: number;
}

/* Dashboard Page Reducer */
export const DevicesReducer  = (state: InitialStateType , action: ActionTypes) => {
  console.log(action);
  switch (action.type) {
      case 'GET_DEVICE_LIST':         
          return {...state, deviceList: action.payload };
      case 'GET_DEVICE_TOTAL':
          return {...state, total: action.payload };
      case 'GET_DEPARTMENT_LIST':
          return {...state, departmentList: action.payload };
      default:
          return state;
  }
}