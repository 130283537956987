import { useState, useContext, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { Container, Row, Button, Alert, Card, CardHeader, CardBody, FormGroup, Label, Input } from 'styled/common';
// import { LoginContext, LoginProvider } from '../contexts';
// import Menu from 'configs/MenuData';
import { fetchLoginAuth } from 'contexts/fetchAction';
import { useTranslation } from 'react-i18next';


/**
 * Loign Page
 */
const Login = (props:any) => {
  const [username, setUsername] = useState<string>('');// The useState hook
  const [password, setPassword] = useState<string>('');// The useState hook
  // const context = useContext(LoginContext)  //React 16.8 support
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  
  useEffect( () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('isLogin');
    sessionStorage.removeItem('expired');
    sessionStorage.removeItem('menu');
  },[]);


  const handleSubmit = async (event:any) => {
    event.preventDefault();  

    if(!username || !password)
    {
      setMessage(`${t("login.input")}`);
    } else {
      const parameter = {
        'username': username,
        'password': password
      }
      setLoading(true);
      //登入驗證
      const result = await fetchLoginAuth(parameter);

      if(result.accessToken) {
        sessionStorage.username = username;
        sessionStorage.isLogin = "true";
        sessionStorage.expired = result.expired;
        sessionStorage.accessToken = result.accessToken;
        //sessionStorage.menu = JSON.stringify(siderBar.menu);  //靜態
        sessionStorage.menu = JSON.stringify(result.menu); //動態      
        props.history.push("/");
        window.location.reload();
      } else {
        setLoading(false);
        // setMessage(`${t("login.auth_error")}`);
        //顯示錯誤訊息
        setMessage(result.msg);
      }
    }
  } 

  return (
    <Container>
        <Row>
           <Card>
              {/* <CardHeader className="text-center"><h3>MSit Admin</h3></CardHeader> */}
              <CardHeader><h3>{t("login.title")}</h3></CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label htmlFor="username">{t("login.account")}</Label>
                    <Input type="text" name="username" id="username" data-testid="username" value={username} onChange={e => setUsername(e.target.value)}/>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="password">{t("login.password")}</Label>
                    <Input type="password" name="password" id="password" data-testid="password" value={password} onChange={e => setPassword(e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                      <Button data-testid="submit" disabled={loading} type="submit">
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>{t("header.login")}</span>
                      </Button>                               
                  </FormGroup>
                  { message && (
                      <FormGroup>
                        <Alert role="alert" data-testid="message">
                          {message}
                        </Alert>
                      </FormGroup>
                  )}
                </form>
              </CardBody>
            </Card>
         
        </Row>          
    </Container>
  );
}

export default withRouter(Login);
// export default Login;