import React from 'react';
import ReactDOM from 'react-dom';
import { Link,BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import './index.css';
import App from 'App';
import Login from 'pages/Login';
import reportWebVitals from 'reportWebVitals';
import { I18nextProvider } from 'react-i18next';
// import './i18n';
import i18n from 'i18n';

//  ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
             <Route exact path='/' render={() => {
                        const isLogin = (sessionStorage.isLogin ? JSON.parse(sessionStorage.isLogin) : false);//預設登入狀態; false 未登入(預設)
                        // const isLogin = (localStorage.isLogin ? JSON.parse(localStorage.isLogin) : false); //預設登入狀態; false 未登入(預設)
                        // const isLogin = true;
                        // console.log('isLogin: ' + isLogin);
                        const expired = sessionStorage.expired;
                        const NowTime = Math.floor(Date.now() / 1000);
                        console.log(`expired: ${expired} , NowTime: ${NowTime}`);

                        if(isLogin){
                            return ( expired < NowTime ? <Redirect to="/login"/> :
                                    <I18nextProvider i18n={i18n}>
                                        <App/>
                                    </I18nextProvider>
                                     
                                   )
                            // return ( <App/>)  
                        } else {
                            return <Redirect to="/login"/>
                        }
                    }}/>
                    <Route path="/login" component={Login} />
                    <Redirect to="/" />
        </Switch>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
