import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
// import { Link,BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// import { Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Input, Alert, Collapse } from 'reactstrap';
// import { Redirect } from 'react-router-dom';
import { PagingState,
         IntegratedPaging,
         DataTypeProvider,
         EditingState
} from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, PagingPanel, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faBell, faSearch } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/Layout';
import { DevicesContext, DevicesProvider } from '../contexts';
import { fetchDeviceData,
         fetchDepartmentData,
         fetchUpdateDeviceData,
         fetchCreateDevice,
         fetchSearchResult } from '../contexts/fetchAction';
import { useTranslation } from 'react-i18next';


/* 取得裝置設備清單 */ 
const Devices = () => {
  const { state } = useContext(DevicesContext) 
  const { pathname } = useLocation()
  // console.log(pathname);
  const { t } = useTranslation();

  return (
    <DevicesProvider>
      <Layout>
          <h2>{t("devices.title")}</h2>
          <hr />
          <Row>
            <Col>
              {/* 裝置電腦數量 */}
              <DevicesTotal />
            </Col>     
          </Row>
          <hr />
          <Row>
          <Col sm={11}>
              {/* 搜尋功能模組 */}
              <SearchBar />
            </Col>
          
            <Col sm={1}>
              {/* 新增裝置設備模組 */}
              <AddDevice buttonLabel= "新增" className= "default-font" />
            </Col>
            </Row>
          <br />
          <Row>
            <Col>
              {/* 裝置電腦清單資料表 */}
              <DevicesTable />
          </Col>
        </Row>
      </Layout>
    </DevicesProvider>
  );
}

  // const CommandButton = ({
  //   onExecute, icon, text, hint, color,
  // }:any) => (
  //   <button
  //     type="button"
  //     className="btn btn-link"
  //     style={{ padding: 11 }}
  //     onClick={(e) => {
  //       onExecute();
  //       e.stopPropagation();
  //     }}
  //     title={hint}
  //   >
  //     <span className={color || 'undefined'}>
  //       {icon ? <i className={`oi oi-${icon}`} style={{ marginRight: text ? 5 : 0 }} /> : null}
  //       {text}
  //     </span>
  //   </button>
  // );
  
  // const EditButton = ({ onExecute }:any) => (
  //   <CommandButton icon="pencil" hint="Edit row" color="text-warning" onExecute={onExecute} />
  // );

  // const EditButton = ({ onExecute }:any) => {
  //   return(
  //     <button type="button" 
  //             className ="btn btn-success"
  //             title="編輯" 
  //             onClick={(e) => { onExecute(); e.stopPropagation(); }}
  //     ><i className="oi oi-pencil" /> 編輯
  //     </button>
  //   )
  // }

  // const DeleteButton = (id:string) => {
  //   return(
  //     <Button>刪除</Button>
  //   )
  // }

  // const CommitButton = (id:string) => {
  //   return(
  //     <Button>儲存</Button>
  //   )
  // }

  // interface ButtonCommand {
  //   [id:string]: object;
  //   edit: object;
  //   delete: object;
  //   commit: object;
  // };

  // const commandComponents:ButtonCommand = {
  //   edit: EditButton,
  //   delete: DeleteButton,
  //   commit: CommitButton
  // };

  // const Command = ({ id, onExecute }:any) => {
  //   const ButtonComponent:any = commandComponents[id];
  //   return (
  //     <ButtonComponent
  //       onExecute={onExecute}
  //     />
  //   );
  // };


/* 裝置電腦數量 */
const DevicesTotal = () => {
  const { state } = useContext(DevicesContext)
  const total = state.total;
  return(
    <Alert color="primary">
        <FontAwesomeIcon icon={faExclamationCircle} /><span className="icon-left">目前使用中電腦數量為: {total} 台</span>
    </Alert>
  )
}

/* 裝置電腦清單資料表 */
const DevicesTable = () => {
   const { state, dispatch } = useContext(DevicesContext)
  //  const Dispatch = useCallback(dispatch, []);  // dispatch 需要使用useCallback 建立依賴的關係
   const departmentList = state.departmentList;
   const deviceList = state.deviceList;   

   const [statausColumns] = useState(['status']);
   const [departmentColumns] = useState(['department_name']);

   const tableColumnExtensions: Table.ColumnExtension[] = [
    { columnName: 'computer_name', width: 170 },
    { columnName: 'owner_id', width: 120 },
    { columnName: 'owner_name', width: 120 },
    { columnName: 'department_name', width: 220 },
    { columnName: 'status', width: 130 },
    { columnName: 'remark', width: 350 },
    { columnName: 'create_date', width: 150 }    
  ];

  //  const [defaultColumnWidths] = useState([
  //    { columnName: 'computer_name', width: 170 },
  //    { columnName: 'owner_id', width: 120 },
  //    { columnName: 'owner_name', width: 120 },
  //    { columnName: 'department_name', width: 240 },
  //    { columnName: 'status', width: 130 },
  //    { columnName: 'remark', width: 350 },
  //    { columnName: 'create_date', width: 150 } 
  //  ]);

  const editColumnMessages = {
    // editCommand: EditButton,
    editCommand: '編輯',
    deleteCommand: '刪除',
    commitCommand: '儲存',
    cancelCommand: '取消',
  };

   //定義欄位
   const [columns] = useState([
     { name: 'computer_name', title: '電腦名稱' },
     { name: 'owner_id', title: '工號' },
     { name: 'owner_name', title: '保管人' },
     { name: 'department_name', title: '部門名稱' },
     { name: 'status', title: '使用狀態' },    
     { name: 'remark', title: '備註' },
     { name: 'update_date', title: '更新日期' }
   ]);
   // 排除編輯清單
   const [editingStateColumnExtensions] = useState([
     { columnName: 'computer_name', editingEnabled: false },
     { columnName: 'update_date', editingEnabled: false },
   ]);

   const getRowId = (row: { id: any; }) => row.id;
 
   const commitChanges = ({ added, changed, deleted }:any) => {
     let changedRows:any;
     if (added) {
       const startingAddedId = deviceList.length > 0 ? deviceList[deviceList.length - 1].id + 1 : 0;
       changedRows = [
         ...deviceList,
         ...added.map((row:any, index:any) => ({
           id: startingAddedId + index,
           ...row,
         })),
       ];
     }
     if (changed) {
       changedRows = deviceList.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
       console.log(changed);
       updateDeviceData(Object.keys(changed), Object.values(changed));
     }
     if (deleted) {
       const deletedSet = new Set(deleted);
       changedRows = deviceList.filter(row => !deletedSet.has(row.id));
     }
     dispatch({ type: "GET_DEVICE_LIST", payload: changedRows });
   };
 
   useEffect( () => {
    //取得裝置清單
    const getDeviceData = async () => {
        const result = await fetchDeviceData();
        if(result.status === 200) {
          dispatch({ type: "GET_DEVICE_LIST", payload: result.data });
          dispatch({ type: "GET_DEVICE_TOTAL", payload: result.total });
        }
    }
    // 取得部門清單
    const getDepartmentData = async () => {
      const result = await fetchDepartmentData();
      if(result.status === 200) {
          dispatch({ type: "GET_DEPARTMENT_LIST", payload: result.data });
      }
    }
    getDeviceData();
    getDepartmentData();
  },[dispatch]);
  //useEffect的第二的參數說明: 
  //[] => 不監控任何變數的改變，所以只會執行一次;
  //[rows, departments] => 當rows 或者 departments 有變動的時候，自動重新觸發 useEffect
  
    //更新裝置資料
    const updateDeviceData = async (id:any, values:any) => {
      const result = await fetchUpdateDeviceData(id, values);
      if(result.status === 200) {
        alert('資料更新成功');
      }
    }

  const BooleanFormatter = ({ value }:any) => (
    <span className={ value ? "badge badge-primary" : "badge badge-secondary" } >
      {value ? '使用中' : '已停用'}
    </span>
  );

  interface SBoolean {
    values: any,
    onValueChange: (newValue: any) => void
  }

  const StatusEditor = ({ values, onValueChange }:SBoolean) => (
    <select
      className="form-control"
      value={values}
      onChange={e => onValueChange(e.target.value === 'true')}
    >
      <option value="false">
        選擇狀態
      </option>
      <option value="true">
        使用中
      </option>
      <option value="false">
        已停用
      </option>
    </select>
  );
  
  interface SDepart {
    values: any,
    onValueChange: (newValue: any) => void
  }

  const DepartmentEditor = ({ values, onValueChange }:SDepart) => (
    <select
      className="form-control"
      value={values}
      onChange={e => onValueChange(e.target.value)}
    >
      { (departmentList ? departmentList.map((i:any) =>
          <option key={i.id} value= {i.department_name}>
             {i.department_name}
          </option>
         ): <option value= {0}>NULL</option>)}
    </select>
  );
  // 狀態 status
  const BooleanTypeProvider = (props:any) => (
    <DataTypeProvider
      formatterComponent={BooleanFormatter}
      editorComponent={StatusEditor}      
      {...props}
    />
  );
  
  //部門 department
  const SelectTypeProvider = (props:any) => (
    <DataTypeProvider
      editorComponent={DepartmentEditor}      
      {...props}
    />
  );
   
   return(
      <Grid 
        rows={deviceList}
        columns={columns}
        getRowId={getRowId}
      >
      <BooleanTypeProvider for={statausColumns} />
      <SelectTypeProvider  for={departmentColumns} />           
      <EditingState
        onCommitChanges={commitChanges}
        defaultEditingRowIds={[0]}
        columnExtensions={editingStateColumnExtensions} /* 編輯排除例外 */
      />
      <PagingState
        defaultCurrentPage={0}
        pageSize={15}
      />
      <IntegratedPaging />
      <Table columnExtensions={tableColumnExtensions} />
      {/* <Table /> */}
      {/* <TableColumnResizing defaultColumnWidths={defaultColumnWidths} /> */}
      <TableHeaderRow />
      <TableEditRow />
      <TableEditColumn
        // showAddCommand
        showEditCommand
        showDeleteCommand
        messages={editColumnMessages}
        //commandComponent={Command}
      />
      <PagingPanel />
      </Grid>
   )
}

/* 新增裝置電腦 */
const AddDevice = (props:any) => {
  const {
    buttonLabel,
    className,   //由上層帶入class
  } = props;

  const { state } = useContext(DevicesContext)
  const departmentList = state.departmentList;

  const [modal, setModal] = useState(false);

  const [deviceName, setDeviceName] = useState('');
  const [ownerSN, setOwnerSN] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [departmentId, setDepartmentId] = useState(0);
  const [remark, setRemark] = useState('');

  const toggle = () => setModal(!modal);
  const changeDeviceName = (event:any) => setDeviceName(event.target.value);
  const changeOwnerSN = (event:any) => setOwnerSN(event.target.value);
  const changeOwnerName = (event:any) => setOwnerName(event.target.value);
  const changeDepartmentId = (event:any) => setDepartmentId(event.target.value);
  const changeRemark = (event:any) => setRemark(event.target.value);  

    // 加入新的電腦設備
    const createDevice = async (event:any) => {
      event.preventDefault();     
      const parameter = {
        'deviceName': deviceName,
        'ownerSN': ownerSN,
        'ownerName': ownerName,
        'departmentId': departmentId,
        'remark': remark
      }
      //console.log(JSON.stringify(parameter));
      //呼叫API
      const result = await fetchCreateDevice(parameter);
      if(result.status === 200) {
        alert(result.msg);
        toggle();
      } else {
        alert(result.msg);
      }      
    }

  return (
    <div>
      <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>新增裝置電腦</ModalHeader>
          <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="deviceName" sm={3}>電腦名稱</Label>
              <Col sm={9}>
                <Input type="text" name="deviceName" id="deviceName" value= {deviceName} onChange={ changeDeviceName} placeholder="請輸入電腦名稱" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="ownerSN" sm={3}>保管人工號</Label>
              <Col sm={9}>
                <Input type="text" name="ownerSN" id="ownerSN" value= {ownerSN} onChange={ changeOwnerSN} placeholder="請輸入保管人工號" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="ownerName" sm={3}>保管人姓名</Label>
              <Col sm={9}>
                <Input type="text" name="ownerName" id="ownerName" value= {ownerName} onChange={ changeOwnerName} placeholder="請輸入保管人姓名" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="department" sm={3}>部門名稱</Label>
              <Col sm={9}>
                <Input type="select" id="departmentId" name="departmentId" onChange={changeDepartmentId} defaultValue={0}>
                  <option value= {0}>請選擇部門</option>
                  { (departmentList ? departmentList.map((i:any) =>
                    <option key={i.id} value= {i.department_id}>
                      {i.department_name}
                    </option>
                ): <option value= {0} selected>請選擇部門</option>)}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="remark" sm={3}>備註說明</Label>
              <Col sm={9}>
                <Input type="textarea" name="remark" id="remark" value= {remark} onChange={ changeRemark} placeholder="備註說明" />
              </Col>
            </FormGroup>
            {/* <FormGroup row>
              <Col sm={3}></Col>
              <Col sm={9}>
                <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="使用中" value= {0} />
              </Col>
            </FormGroup> */}
          </Form>  
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={createDevice}>確認</Button>{' '}
              <Button color="secondary" onClick={toggle}>取消</Button>
          </ModalFooter>
        </Modal>
    </div>
  );
}

/* 修改裝置電腦 */
// const EditDevice = (props:any) => {
//   const {
//     buttonLabel,
//     className
//   } = props;

//   const [modal, setModal] = useState(false);

//   const toggle = () => setModal(!modal);

//   return (
//     <div>
//       <Button size="sm" color="success" onClick={toggle}>{buttonLabel}</Button>
//         <Modal isOpen={modal} toggle={toggle} className={className}>
//           <ModalHeader toggle={toggle}>修改裝置電腦</ModalHeader>
//           <ModalBody>
//             Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//           </ModalBody>
//           <ModalFooter>
//               <Button color="primary" onClick={toggle}>確認</Button>{' '}
//               <Button color="secondary" onClick={toggle}>取消</Button>
//           </ModalFooter>
//         </Modal>
//     </div>
//   );
// }

/* 搜尋 */
const SearchBar = () => {
  const { state, dispatch } = useContext(DevicesContext)
  // const Dispatch = useCallback(dispatch, []);  // dispatch 需要使用useCallback 建立依賴的關係
  const departmentList = state.departmentList;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  
  const [computerName, setComputerName] = useState(''); 
  const [ownerSN, setOwnerSN] = useState('');  
  const [ownerName, setOwnerName] = useState('');
  const [status, setStatus] = useState(1);
  const [departmentId, setDepartmentId] = useState('');
  

  //篩選1 電腦名稱
  const changeComputerName = async (event:any) => {
    setComputerName(event.target.value);
    // console.log(event.target.value);

    const parameter = {
      'computerName': event.target.value,
      'ownerSN': ownerSN,
      'ownerName': ownerName,
      'departmentId': departmentId,
      'status': status
    };
    console.log(parameter);

    const result = await fetchSearchResult(parameter);
    if(result.status === 200) {
      dispatch({ type: "GET_DEVICE_LIST", payload: result.data }); 
    }
  } 

  //塞選2 電腦狀態
  const changeStatus = async (event:any) => {
    setStatus(event.target.value);

    const parameter = {
      'computerName': computerName,
      'ownerSN': ownerSN,
      'ownerName': ownerName,
      'departmentId': departmentId,
      'status': event.target.value
    };
    // console.log(parameter);

    const result = await fetchSearchResult(parameter);
    if(result.status === 200) {
      dispatch({ type: "GET_DEVICE_LIST", payload: result.data }); 
    }
  }
  
  //塞選3 電腦保管人工號
  const changeOwnerSN = async (event:any) => {
    setOwnerSN(event.target.value);

    const parameter = {
      'computerName': computerName,
      'ownerSN': event.target.value,
      'ownerName': ownerName,
      'departmentId': departmentId,
      'status': status
    };
    console.log(parameter);

    const result = await fetchSearchResult(parameter);
    if(result.status === 200) {
      dispatch({ type: "GET_DEVICE_LIST", payload: result.data }); 
    }
  }

  //塞選4 電腦保管人姓名
  const changeOwnerName = async (event:any) => {
    setOwnerName(event.target.value);

    const parameter = {
      'computerName': computerName,
      'ownerSN': ownerSN,
      'ownerName': event.target.value,
      'departmentId': departmentId,
      'status': status
    };
    // console.log(parameter);

    const result = await fetchSearchResult(parameter);
    if(result.status === 200) {
      dispatch({ type: "GET_DEVICE_LIST", payload: result.data }); 
    }
  }

  //塞選5 部門名稱
  const changeDepartmentId = async (event:any) => {
    setDepartmentId(event.target.value);

    const parameter = {
      'computerName': computerName,
      'ownerSN': ownerSN,
      'ownerName': ownerName,
      'departmentId': event.target.value,
      'status': status
    };
    console.log(parameter);

    const result = await fetchSearchResult(parameter);
    if(result.status === 200) {
      dispatch({ type: "GET_DEVICE_LIST", payload: result.data }); 
    }
  }  

  // const search = async (event:any) => {
  //   event.preventDefault();
  //   const parameter = {
  //     'computerName': computerName,
  //     'ownerSN': ownerSN,
  //     'ownerName': ownerName,
  //     'departmentId': departmentId,
  //     'status': status
  //   };

  //   const result = await fetchSearchResult(parameter);
  //   if(result.status === 200) {
  //     dispatch({ type: "GET_DEVICE_LIST", payload: result.data }); 
  //   }
  // }

  return(
    <div>
    <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}><FontAwesomeIcon icon={faSearch} />{'   '}搜尋&篩選</Button>
    <Collapse isOpen={isOpen}>
    <Form inline>
      <FormGroup className="mr-sm-2">
        <Label className="mr-sm-2">電腦名稱 :</Label>
        <Input type="text" id="computerName" name="computerName" value= { computerName } onChange={changeComputerName} />
      </FormGroup>
      <FormGroup className="mr-sm-2">
        <Label className="mr-sm-2">工號 :</Label>
        <Input type="text" id="ownerSN" name="ownerSN" value= { ownerSN } onChange={changeOwnerSN} />
      </FormGroup>
      <FormGroup className="mr-sm-2">
        <Label className="mr-sm-2">保管人 :</Label>
        <Input type="text" id="ownerName" name="ownerName" value= { ownerName } onChange={changeOwnerName} />
      </FormGroup>
      <FormGroup className="mr-sm-2">
      <Label className="mr-sm-2">部門 :</Label>
        <Input type="select" id="departmentId" name="departmentId" onChange={changeDepartmentId} defaultValue={0}>
                  <option value= {'ALL'}>全部</option>
                  { (departmentList ? departmentList.map((i:any) =>
                    <option key={i.id} value= {i.department_id}>
                      {i.department_name}
                    </option>
                ): <option value= {'ALL'}>全部</option>)}
        </Input>
      </FormGroup>
      <FormGroup className="mr-sm-2">
        <Label className="mr-sm-2">裝置狀態 :</Label>
        <Input type="select" id="status" name="status" onChange={changeStatus} defaultValue={"1"}>
           <option value="2" >全部</option>
           <option value="1" >使用中</option>
           <option value="0">已停用</option>      
        </Input>  {'   '}
      </FormGroup>
      {/* <Button color="success" onClick={search}>搜尋</Button> */}
  </Form>
    </Collapse>
  </div>
  );
}

// export default Devices;
export default withRouter(Devices);