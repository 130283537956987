import React, { Component, useState, useEffect } from 'react';
import styled from "styled-components";
import { device } from "../styled/device";
import Header from './Header';
import Sidebar from './Sidebar';
// import classnames from 'classnames';
import {Link, NavLink as RRNavLink, withRouter, matchPath } from 'react-router-dom';
// import './Styles.css';

const Wrapper = styled.div`
    display: flex;
    /* align-items: stretch; */
    width: 100%;
`;

const Content = styled.div.attrs((props) =>{
})`
  //Mobile 平台
  @media ${device.mobileL} {
    /* 帶入參數 state */
    width: 100%;
    background-color: #eee;
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
  }

  //Desktop 平台
  @media ${device.desktop} {
    /* 帶入參數 state */
    width: ${props => (props.state ? `100%` : `calc(100% - 250px)`)};
    background-color: #eee;
    padding-top: 70px;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
  }

`;

const Layout = (props:any) => {
    const [isOpen , setIsOpen] = useState(false); /* toggle-menu */

    return (
          <>
            <Wrapper>
              <Header setIsOpen = {setIsOpen} isOpen = {isOpen}  />
              <Sidebar isOpen = {isOpen}/>
              {/* <!-- Page Content --> */}
                <Content state={isOpen}>
                     {props.children}
                </Content>
            </Wrapper>  
            {/* <div className="footer">
              <p className="text-center">Copyright ©2019 Tim v0.1.6 </p>
            </div> */}
        </>
    );
  }

export default withRouter(Layout);