import React, { useEffect, useContext, useState} from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane, Alert, Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import styled from "styled-components";
import { Container, Row, CardHeader } from 'styled/common';
import Layout from 'components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faExclamationCircle, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import { DashboardContext, DashboardProvider } from 'contexts';
import { fetchDepartmentDeviceList, fetchDashboardList } from 'contexts/fetchAction';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Bar, Pie, Doughnut, Line, Radar } from 'react-chartjs-2';


const Col = styled.div.attrs(() => ({
  className: 'col-lg col-lg-2 mb-3',
}))`  
`;

const Col4 = styled.div.attrs(() => ({
  className: 'col-lg col-lg-4',
}))`
`;

const Col8 = styled.div.attrs(() => ({
  className: 'col-lg col-lg-8',
}))`
`;

const Col4B = styled.div.attrs(() => ({
  className: 'col-lg col-lg-4',
}))`
  background-color: #06074d;  
`;

const ColAlert = styled.div.attrs(() => ({
  className: 'col-lg',
}))`
  margin-top: 10px;
  margin-right:50%;  
`;

const Card = styled.div.attrs(() => ({
  className: 'card card-inverse',
}))`
  color: white;
  background-color: #34b434;  
`;

const CardBody = styled.div.attrs(() => ({
  className: 'card-body',
}))`
  background-color: #71b871;
  text-align:center;
`;

const Span = styled.span`
    font-size: 32px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 1px;
`;

const Bk = styled.div`
    background-color: #ccc;
    /* margin-left:1px; */
    margin-top:10px;
    padding: 5px;
    border-radius: 1%;
    border: 1px #aaa solid;
`;

const P = styled.p`
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  size: 12px;
  color: green;
`;

const Span2 = styled.span`
    margin-left: 5px;
    margin-right: 1px;
`

// const option = {
//   responsive: true,
//   maintainAspectRatio: false,
//   tooltips: {
//       backgroundColor: "rgb(255,255,255)",
//       bodyFontColor: "#858796",
//       borderColor: '#dddfeb',
//       borderWidth: 1,
//       xPadding: 15,
//       yPadding: 15,
//       displayColors: false,
//       caretPadding: 10,
//   },
//   legend: {
//       labels: {
//           fontColor: 'rgb(255,255,255)'
//       },
//       display: true
//   },
//   cutoutPercentage: 70,
// }


// 使用 reducer hook
const Dashboard = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation()
    // console.log(pathname);

  return (
      <DashboardProvider>
        <Layout>
            <Container>
              <h2>{t('dashboard.title')}</h2>
              <hr />
              <DepartmentDeviceList />
            </Container>
        </Layout>
      </DashboardProvider>
  );
}

// 顯示部門電腦數
const DepartmentDeviceList = () => {
  const { state, dispatch } = useContext(DashboardContext);
  const { total, departments, todoHour, closedHour, todoHourTotal, caseList, allHourTotal } = state;
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('1');
  
  useEffect( () => {
    //取得各部門狀態數量
    const getDepartmentDeviceList = async () => {
       const result = await fetchDepartmentDeviceList();
      //  console.log(result);
       if(result.status === 200) {
          dispatch({ type: "GET_DEPARTMENT_LIST", payload: result.data });
          dispatch({ type: "GET_DEVICE_TOTAL", payload: result.total });
       }
    }

    const getDashboardList = async () => {
      const result = await fetchDashboardList();
      // console.log(result);
      if(result.status === 200) {
          dispatch({type: "GET_SYSTEM_TODO_HOUR_TOTAL", payload: result.data.SystemHourList}) // 各系統待處理案件/工時
          dispatch({type: "GET_SYSTEM_CLOSED_HOUR_TOTAL", payload: result.data.SystemClosedHourList}) // 本月各系統結案工時
          dispatch({type: "GET_TODO_HOUR_TOTAL", payload: result.data.TodoHourTotal}) // 取得累計待處理工時
          dispatch({type: "GET_DIFFICULT_CASE_LIST", payload: result.data.CaseList}) // 高難度/高複雜度案件清單
          dispatch({type: "GET_ALL_HOUR_TOTAL", payload: result.data.AllHourTotal}) // 累積執行總工時
      }
    }
    getDepartmentDeviceList();
    getDashboardList();

  },[dispatch]);

  const toggle = (tab:any) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

   return (
      <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            資訊統計
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            電腦統計
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">        
          <Row>
            <Col4>
              <Bk>
              
              <h4>
                各系統待處理案件/工時
                <Detail />
              </h4>
              <ITRadar data = {todoHour} />
              <br />
              <Alert color="primary">
                {(todoHour.reduce((a:any, item:any) =>  a = a + item.FormTotal , 0 ))} 件 /
                {(todoHour.reduce((a:any, item:any) =>  a = a + item.HourTotal , 0 ))} 小時
              </Alert>
              <P><FontAwesomeIcon icon = {faCommentAlt} /><Span2>此項目只統計在職人員在處理案件與工時</Span2></P> 
              </Bk>

            </Col4>                
            <Col4>
               <Bk>
               <h4>本月各系統結案工時</h4>
               <ITDoughnut data = {closedHour} />
               <br />
               <Alert color="primary">本月總工時 : {(closedHour.reduce((a:any, item:any) =>  a = a + item.HourTotal , 0 ))} 小時</Alert>
               <P><FontAwesomeIcon icon = {faCommentAlt} /><Span2>此項目只統計在職人員本月結案的工時</Span2></P>   
               </Bk>

            </Col4>    
            <Col4>
              <Bk>
              <h4>累計待處理工時</h4>
              <ITBar data = {todoHourTotal} />
              <br />
              <Alert color="primary">累計待處理工時 : {(todoHourTotal.reduce((a:any, item:any) =>  a = a + item.HourTotal , 0 ))} 小時</Alert> 
              <P><FontAwesomeIcon icon = {faCommentAlt} /><Span2>此項目只統計在職人員目前待處理案件的工時</Span2></P> 
              </Bk>
            </Col4>
            <Col8>
              <Bk>
                <Table>
                <thead>
                  <tr>
                    <th scope="col">單號</th>
                    <th scope="col">系統</th>
                    <th scope="col">需求單主旨</th>
                    <th scope="col">執行人員</th>
                    <th scope="col">預計上線日期</th>
                    <th scope="col">逾期天數</th>
                  </tr>
                </thead>
                <tbody>
                { caseList && caseList.map((item:any, index:any) =>
                  <tr key={index}>
                    <td>{item.SerialNumber}</td>
                    <td>{item.hidSystemText}</td>
                    <td>{item.txtRequestSubject}</td>
                    <td>{item.txtPrincipalName}</td>
                     <td>{item.DateExpectedCompleted}</td>
                    <td>{item.OverTime}</td>
                  </tr>
                )}
                </tbody>
                </Table>
                <P><FontAwesomeIcon icon = {faCommentAlt} /><Span2>此項目統計包含在職、離職人員所負責的案件</Span2></P>   
              </Bk>

            </Col8>
            <Col4>
              <Bk>
              <h4>累積執行總工時</h4>
              <ITLine data = {allHourTotal} />
              <br />
               <Alert color="primary">累積執行總工時 : {(allHourTotal.reduce((a:any, item:any) =>  a = a + item.HourTotal , 0 ))} 小時</Alert>
               <P><FontAwesomeIcon icon = {faCommentAlt} /><Span2>此項目統計包含在職、離職人員所有的總案件工時</Span2></P>   
              </Bk> 
            </Col4>             
          </Row>
          <br />
        </TabPane>
        <TabPane tabId="2">
        <Row>
           <ColAlert>
              <Alert color="primary">
                {/* <FontAwesomeIcon icon={faExclamationCircle} /><span className="icon-left">目前使用中電腦數量為: {total} 台</span> */}
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span className="icon-left">{t('dashboard.now_used_devices_number')}{total}
                {t('dashboard.pcs')}</span>
              </Alert>
           </ColAlert>
         </Row>
         <Row>
        { departments && departments.map((item:any, index:any) =>
          <Col key={index}>
            <Card>
              <CardHeader><h5>{item.department_name}</h5></CardHeader>
              <CardBody>
                <FontAwesomeIcon icon={faLaptop} />
                <Span>{item.total}</Span>
              </CardBody>
            </Card>
          </Col>
       )}
    </Row> 
        </TabPane>
      </TabContent>
      </div>    
   );
}

//本月各系統結案工時 Doughnut
const ITRadar = (props:any) => {
  const {
    data
 } = props;

 const [ labels, setLabels] = useState([])
 const [ hour, setHour] = useState([])
  
 useEffect( () => {
  //  console.log(data);
   setLabels(data.map(function(item:any) { return item.hidSystemText}));
   setHour(data.map(function(item:any) { return item.HourTotal}));
 },[data]);

//  const options = {
//   responsive: true,
//   tooltips: {
//       backgroundColor: "rgb(255,255,255)",
//       bodyFontColor: "#858796",
//       borderColor: '#dddfeb',
//       borderWidth: 1,
//       xPadding: 15,
//       yPadding: 15,
//       displayColors: false,
//       caretPadding: 10,
//   },
//   legend: {
//       labels: {
//           fontColor: 'rgb(192,192,192)'
//       },
//   },
//   cutoutPercentage: 70,
// }

//設定字體大小
const options = {
  scale: {
    pointLabels: {
      fontSize: 14,
  },
}
}

  //共9 個系統
  const data2 = {
    labels: labels,
    datasets: [
    {
      label: '各系統待處理案件/工時',
      data: hour,
      backgroundColor: 'rgba(54, 162, 235, 0.5)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
      },
    ],
  } 
  return(
    <Radar options = {options} data={data2} />
  );
}

//本月各系統結案工時 Doughnut
const ITDoughnut = (props:any) => {
  const {
    data
 } = props;

 const [ labels, setLabels] = useState([])
 const [ hour, setHour] = useState([])
  
 useEffect( () => {
  //  console.log(data);
   setLabels(data.map(function(item:any) { return item.hidSystemText}));
   setHour(data.map(function(item:any) { return item.HourTotal}));
 },[data]);

  //共9 個系統
  const data2 = {
    labels: labels,
    datasets: [
    {
      label: '本月各系統結案工時',
      data: hour,

      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(238, 130, 22, 0.5)',
        'rgba(28, 31, 226, 0.5)',
        'rgba(233, 18, 36, 0.5)',
        'rgba(12, 117, 29, 0.5)',
        'rgba(224, 221, 33, 0.5)',
      ],
      borderColor: "rgb(64,64,64)",
      //   borderColor: [
      //   'rgba(255, 99, 132, 1)',
      //   'rgba(54, 162, 235, 1)',
      //   'rgba(255, 206, 86, 1)',
      //   'rgba(75, 192, 192, 1)',
      //   'rgba(153, 102, 255, 1)',
      //   'rgba(255, 159, 64, 1)',
      //   'rgba(49, 47, 190, 1)',
      //   'rgba(190, 47, 114, 1)',
      //   'rgba(14, 121, 62, 1)',        
      // ],
      borderWidth: 1,
      },
    ],
  } 
  return(
    <Doughnut data={data2} />
  );
}

// 取得累計待處理工時 Bar
const ITBar = (props:any) => {
  const {
     data
  } = props;

  const [ labels, setLabels] = useState([]);
  const [ hour, setHour] = useState([])
 
  useEffect( () => {
    // console.log(data);
    setLabels(data.map(function(item:any) { return item.DateExpectedCompletion})); // 日期
    setHour(data.map(function(item:any) { return item.HourTotal}));  //處理工時  
  },[data]);

  // const options = {
  //   responsive: true,
  //   tooltips: {
  //       backgroundColor: "rgb(255,255,255)",
  //       bodyFontColor: "#858796",
  //       borderColor: '#dddfeb',
  //       borderWidth: 1,
  //       xPadding: 15,
  //       yPadding: 15,
  //       displayColors: false,
  //       caretPadding: 10,
  //   },
  //   legend: {
  //       labels: {
  //           fontColor: 'rgb(192,192,192)'
  //       },
  //   },
  //   cutoutPercentage: 70,
  // }

  const data2 = {
    labels: labels,
    datasets: [
      {
        label: '累計待處理工時',
        data: hour,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  }
  return(
    <Bar data={data2} /> 
  )
   
} 

// 累積執行總工時 Line
const ITLine = (props:any) => {
  const {
     data
  } = props;

  const [ labels, setLabels] = useState([]);
  const [ hour, setHour] = useState([])
  // const [ backgroundColor, setBackgroundColor] = useState([])
  // const [ borderColor, setBorderColor] = useState([])
 
  useEffect( () => {
    // console.log(data);
    setLabels(data.map(function(item:any) { return item.HistoryHourTotal})); // 日期
    setHour(data.map(function(item:any) { return item.HourTotal}));  //處理工時
    
    // setBackgroundColor(data.map(function(item:any) { return 'rgba(54, 162, 235, 0.5)'}));
    // setBorderColor(data.map(function(item:any) { return 'rgba(54, 162, 235, 1)'}));
  },[data]);

  // const options = {
  //   responsive: true,
  //   tooltips: {
  //       backgroundColor: "rgb(255,255,255)",
  //       bodyFontColor: "#858796",
  //       borderColor: '#dddfeb',
  //       borderWidth: 1,
  //       xPadding: 15,
  //       yPadding: 15,
  //       displayColors: false,
  //       caretPadding: 10,
  //   },
  //   legend: {
  //       labels: {
  //           fontColor: 'rgb(192,192,192)'
  //       },
  //   },
  //   cutoutPercentage: 70,
  // }

  const data2 = {
    labels: labels,
    datasets: [
      {
        label: '累積執行總工時',
        data: hour,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        // backgroundColor: backgroundColor,
        // borderColor: borderColor,
        borderWidth: 1,
      },
    ],
  }
  return(
    <Line data={data2} /> 
  )
   
}

/**
 * 顯示各系統待處理案件/工時系統分類明細
 */ 
 const Detail = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    console.log(modal);
  }

  return (
    <span className='float-right'>
      <Button color="secondary" size='sm' onClick={toggle}>明細</Button>
        <Modal size='lg' isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>各系統待處理案件/工時系統分類明細</ModalHeader>
          <ModalBody>
              <Table bordered>
                 <tr>
                    <td width={'20%'}>資訊軟硬體</td>
                    <td>電腦故障、網路問題、新增設備需求、系統軟體安裝、帳號申請、帳號需求(新增/修改/刪除)、其他</td>
                 </tr>
                 <tr>
                  <td width={'20%'}>核心自主系統</td>
                  <td>HRM2 人力資源、TTP 平版打卡</td>
                </tr>
                <tr>
                  <td width={'20%'}>業務自主系統</td>
                  <td>IFOMS 產外、SFOMS 社福、ACCOUNT 應收管理、CRM 客戶關懷、CRM2 業務管理、Dispatch 派工、DMS 住宿服務</td>
                </tr>
                <tr>
                  <td width={'20%'}>套裝半自主系統</td>
                  <td>ERP 系統、BPM 電子簽核、BI 報表中心、MobileApp 行動平台、Pilot 發票系統</td>
                </tr>
                <tr>
                  <td width={'20%'}>額外開發專案</td>
                  <td>WEB 網站設計、評估/規劃-監控設備、評估/規劃-監控通訊、評估/規劃-會議室設備、UniPros 統博</td>
                </tr>
              </Table>
          </ModalBody>
        </Modal>
    </span>
  );
}

export default withRouter(Dashboard);
// export default Dashboard;

