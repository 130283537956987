type ActionTypes =
  | { type: 'GET_PERSONS_MONTH_LIST', payload: any }
  | { type: 'GET_PERSONS_WAIT_HOUR_LIST', payload: any }

// type DepartmentType = {
//     department: string;
//     total: number;
//   }
  
type InitialStateType = {
    monthList: any;
    hourList: any;
}

/* Persons Page Reducer */
export const PersonsReducer  = (state: InitialStateType , action: ActionTypes) => {
  console.log(action);
  switch (action.type) {
      case 'GET_PERSONS_MONTH_LIST':         
          return {...state, monthList: action.payload };
      case 'GET_PERSONS_WAIT_HOUR_LIST':
          return {...state, hourList: action.payload };
      default:
          return state;
  }
}



