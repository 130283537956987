import React, { Component, useState } from 'react';
import { Link,BrowserRouter as Router, Switch, Route, Redirect, withRouter, useLocation } from 'react-router-dom';
// import { Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
// import { Button } from 'reactstrap';
import Dashboard from 'pages/Dashboard';
// import Demo from './pages/Demo';
// import Forms from './pages/Forms';
import Devices from 'pages/Devices';
import Erp from 'pages/Erp';
import Services from 'pages/Services';
import Employees from 'pages/Employees';
import ServicesManage from 'pages/services-manage';
import Contracts from 'pages/contracts';
import Permissions from 'pages/permissions';
import Persons from 'pages/persons';
import Units from 'pages/units';
// import logo from './logo.svg';
import 'App.css';
// import { throwStatement } from '@babel/types';
// import { DashboardContext, DashboardProvider } from './Providers';


const App = () => {
  const { pathname } = useLocation()
  // console.log(pathname);
  return (
    <>
    <Router basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route exact path='/' component={Dashboard}/>
      <Route exact path='/dashboard' component={Dashboard}/>
      {/* <Route exact path='/demo' component={Demo}/> */}
      <Route exact path='/devices' component={Devices}/>
      <Route exact path='/erp' component={Erp}/> 
      <Route exact path='/employees' component={Employees}/>     
      <Route exact path='/services' component={Services}/>
      <Route exact path='/manage' component={ServicesManage}/>
      <Route exact path='/contracts' component={Contracts}/>
      <Route exact path='/permissions' component={Permissions}/>
      <Route exact path='/persons' component={Persons}/>
      <Route exact path='/units' component={Units}/>
      <Redirect to="/" />
    </Switch>
  </Router>
  </>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default withRouter(App);
