import styled from "styled-components";

export const Button = styled.button.attrs(() => ({
  className: 'btn btn-success btn-block',
}))``;

export const Alert = styled.div.attrs(() => ({
  className: 'alert alert-danger',
}))``;

export const Container = styled.div.attrs(() => ({
  className: 'container-fulid',
}))``;

export const Row = styled.div.attrs(() => ({
  className: 'row',
}))``;

export const Col = styled.div.attrs(() => ({
  className: 'col-lg',
}))``;

export const Card = styled.div.attrs(() => ({
  className: 'card card-top',
}))`
  width:300px;
  position:absolute;
  left:50%;
  top:10%;
  margin-left:-150px;
`;

export const CardHeader = styled.div.attrs(() => ({
  className: 'card-header text-center',
}))``;

export const CardBody = styled.div.attrs(() => ({
  className: 'card-body',
}))``;

export const FormGroup = styled.div.attrs(() => ({
  className: 'form-group',
}))``;

export const Label = styled.label.attrs(() => ({
  className: 'Label',
}))``;

export const Input = styled.input.attrs(() => ({
  className: 'form-control',
}))``;