import React, { useEffect, useState } from 'react';
import { NavLink as RRNavLink, withRouter, matchPath } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import styled from "styled-components";
import { device } from "../styled/device";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt,
        //  faUser,
        faSignInAlt,
        //  faGamepad,
        //  faTable,
         faList,
         faLaptop,
         faBell,
         faFileContract,
         faKey, 
         faInfoCircle,
        //  faCog,
        //  faBars,
         faServer } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'reactstrap';
import info from "../../package.json";
import { useTranslation } from 'react-i18next';
// import './Styles.css';

const NavMenu = styled.nav.attrs((props) =>{
})`
    /* 帶入參數 state */
    width: 250px;
    height: 100vh;
    position: fixed; 
    top: 0;
    left: 0;
    padding-top: 70px;
    z-index: 999;
    background: #53545a;
    color: #fff;
    /* opacity: 0.9; */
    transition: all 0.3s;

    //Mobile 平台 (解析度大於320px(mobileS) 依照狀態顯示或不顯示)
    @media ${device.mobileS} {  
      margin-left: ${props => (props.state ? `0` : `-250px`)}; 
    }

    //Desktop 平台(解析度大於1440px(desktop) 依照狀態顯示或不顯示)
    @media ${device.desktop} {  
      margin-left: ${props => (props.state ? `-250px` : `0px`)};
    }   

`;

const Hr = styled.hr`
  border: 0.5px solid #666;
`;


const NavUl = styled.ul.attrs(() => ({
  className: 'list-unstyled'
}))`
  p {
    color: #fff;
    padding: 10px;
  }
`;

const NavLi = styled.li.attrs((props) =>{
})`
  a {
    padding: 10px;
    font-size: 1.0em;
    display: block;
    ${props => (props.state ? `
      background-color: #4189C7;
      color: white;
      `: ``)};
  }
  
  a:hover {
    color: #7386D5;
    background: #fff;
  }
`;


/* 行動版 UserNav (預設顯示，解析度大於1440px(desktop) 則不顯示) */
const UserNavMobile = styled.div.attrs((props) => ({
  // className: "badge badge-primary"
  className: props.className
}))`
  display: block;

  @media ${device.desktop} {
    display: none;
  }
`;

const Siderbar = (props:any) => {
  const {
    isOpen,
  } = props;
  
  const [menu, setMenu] = useState([]);
  const [modal, setModal] = useState(false); //控制版本資訊Modal

  // const [toggle, setToggle] = useState(false);

  useEffect( () => {
    if(sessionStorage.menu && !undefined) {
        // 取得Menu
        // console.log(localStorage.menu);
        setMenu(JSON.parse(sessionStorage.menu));
        // const menu = localStorage.menu ? JSON.parse(localStorage.menu) : [];
    }
  },[]);

  const handleModal = () => setModal(!modal);

  const showIcon = (icon: string) => {
    switch(icon) {
      case 'faBell':
        return <FontAwesomeIcon icon = {faBell} />;
      case 'faTachometerAlt':
        return <FontAwesomeIcon icon = {faTachometerAlt} />;
      case 'faLaptop':
        return <FontAwesomeIcon icon = {faLaptop} />;
      case 'faServer':
        return <FontAwesomeIcon icon = {faServer} />;
      case 'faList':
        return <FontAwesomeIcon icon = {faList} />;
      case 'faFileContract':
          return <FontAwesomeIcon icon = {faFileContract} />;
      case 'faKey':
          return <FontAwesomeIcon icon = {faKey} />;
      default:
        break;
    }
  }

  const loginOutClick = () => {
    alert("你已經登出了");
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('isLogin');
    sessionStorage.removeItem('expired');
    sessionStorage.removeItem('menu');
    //直接重新整理
    window.location.reload();
  }
  
  /* 登出按鈕 */
  const IsLogin = () => {
    return(
      <div>
      { sessionStorage.isLogin === 'false' ?
      <NavLi>
        <NavLink tag={RRNavLink} to="#" >
          <FontAwesomeIcon icon = {faSignInAlt} />
          <span className="icon-left">登入</span>
        </NavLink>
      </NavLi>
      :
      <NavLi>
        <NavLink tag={RRNavLink} to="#" onClick= {loginOutClick} >
          <FontAwesomeIcon icon = {faSignInAlt} />
          <span className="icon-left">登出</span>
          </NavLink>
      </NavLi>
      }
      </div>
    )
  }

  /* 版本資訊 */
const VersionInfo = (props:any) => {
  const {
    title,
    modal,
    setModal
  } = props;

  const MODE = process.env.MODE;  
  const deviceInfo = window.navigator.userAgent;

  // Get the size of the device screen
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
    
  // Get the browser window size
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const { t } = useTranslation();

  const toggle = () => {
    setModal(!modal);
    // console.log(`model : ${modal}`);
  } 
  return (
    <div>
        {/* <FontAwesomeIcon icon={faInfoCircle} /><span className="icon-left">{t('header.version')}</span> */}
        <NavLi>
        <NavLink tag={RRNavLink} to="#" onClick= {toggle} >
          <FontAwesomeIcon icon = {faInfoCircle} />
          <span className="icon-left">{t('header.version')}</span>
          </NavLink>
       </NavLi>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody>
            <p>Project: <Button color="success">{info.name}</Button></p>
            <p>Version: <Button color="primary">v{info.version}</Button></p>
            <p>Mode: { MODE} </p>
            <p>DeviceInfo: { deviceInfo} </p>
            <p>screen: {screenWidth} X {screenHeight} </p>
            <p>window: {windowWidth} X {windowHeight} </p>
          </ModalBody>
          {/* <ModalFooter>
              <Button color="primary" onClick={toggle}>確認</Button>{' '}
          </ModalFooter> */}
        </Modal>
    </div>
  );
}
 
  return (      
        <>
                {/* <!-- Sidebar --> */}
                <NavMenu state={isOpen}>
                  <NavUl>
                    {/* Menu Start */ }
                    { menu.map((i:any) =>
                      <NavLi key= {i.menu_id} state={ matchPath(window.location.pathname, { path: i.menu_path }) ? true : false}>
                      {/* <li key= {i.menu_id} className={ matchPath(window.location.pathname, { path: i.menu_path }) ? 'on-active ' : ''}>  */}
                        <NavLink tag={RRNavLink} to={i.menu_path}>
                          {showIcon(i.menu_icon)} 
                          <span className="icon-left">{i.menu_name}</span>
                        </NavLink>
                       {/* </li> */}
                      </NavLi>                
                   )}
                   <UserNavMobile>
                      <Hr />                  
                      <VersionInfo title = '版本資訊' modal = {modal} setModal = {setModal} />
                      <IsLogin />
                   </UserNavMobile>
                    {/* Menu End */ }
                  </NavUl>
                </NavMenu>
        </>
    );
}

export default withRouter(Siderbar);