type ActionTypes =
  | { type: 'GET_DEPARTMENT_LIST', payload: any }
  | { type: 'GET_DEVICE_TOTAL', payload: any }
  | { type: 'GET_SYSTEM_TODO_HOUR_TOTAL', payload: any } // 各系統待處理案件/工時
  | { type: 'GET_SYSTEM_CLOSED_HOUR_TOTAL', payload: any }  // 本月各系統結案工時
  | { type: 'GET_TODO_HOUR_TOTAL', payload: any } // 取得累計待處理工時
  | { type: 'GET_DIFFICULT_CASE_LIST', payload: any } // 高難度/高複雜度案件清單
  | { type: 'GET_ALL_HOUR_TOTAL', payload: any } // 累積執行總工時





// type DepartmentType = {
//     department: string;
//     total: number;
//   }
  
type InitialStateType = {
    departments: any;
    total: number;
    todoHour: any;
    closedHour: any;
    todoHourTotal: any;
    allHourTotal: any;
    caseList: any;
}

/* Dashboard Page Reducer */
export const DashboardReducer  = (state: InitialStateType , action: ActionTypes) => {
  console.log(action);
  switch (action.type) {
      case 'GET_DEPARTMENT_LIST':         
          return {...state, departments: action.payload };
      case 'GET_DEVICE_TOTAL':
          return {...state, total: action.payload };
      case 'GET_SYSTEM_TODO_HOUR_TOTAL':
            return {...state, todoHour: action.payload };
      case 'GET_SYSTEM_CLOSED_HOUR_TOTAL':
          return {...state, closedHour: action.payload };
      case 'GET_TODO_HOUR_TOTAL':
          return {...state, todoHourTotal: action.payload };
      case 'GET_DIFFICULT_CASE_LIST':
          return {...state, caseList: action.payload };
      case 'GET_ALL_HOUR_TOTAL':
          return {...state, allHourTotal: action.payload };
      default:
          return state;
  }
}



