import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
// import { Link,BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// import { Row, Col, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import { Alert, Badge, Table, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
// import { Redirect } from 'react-router-dom';
import Layout from '../components/Layout';
import { ErpContext, ErpProvider } from '../contexts';
import { fetchNotifyData, fetchLogData } from '../contexts/fetchAction';
import { useTranslation } from 'react-i18next';

    //取得目前時間
    function getNowFormatDate(dt = '') {
      var date:any = '';
      if(dt) {
          date = dt;
      }else {
          date = new Date();
      }
      // var date = (dt?new Date():dt);
      // var date = new Date();
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
          + " " + date.getHours() + seperator2 + date.getMinutes()
          + seperator2 + date.getSeconds();
      return currentdate;
  }

const Erp = () => {
  const { state, dispatch } = useContext(ErpContext);
  const [update, setUpdate] = useState(getNowFormatDate());// The useState hook
  const [activeTab, setActiveTab] = useState('1');
  const { pathname } = useLocation()
  // console.log(pathname);
  const { t } = useTranslation();

  const toggle = (tab:string) => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  // const Dispatch = useCallback(dispatch, []);  // dispatch 需要使用useCallback 建立依賴的關係


  useEffect( () => {
    let id = setInterval(() => {
      setUpdate(getNowFormatDate());
    },10000)
    return () => clearInterval(id);
  },[update]);  //更新的變量，重新Render

  // const setCount = () => {
  //   const number = 100;
  //   dispatch({ type: "SET_ERP_COUNT", payload: number });
  // }

return (
     <ErpProvider>
      <Layout>
          <h2>{t("erp.title")}</h2>
          <hr />
          <div className="text-right">
            最後更新時間: {update}
          </div>
          <Nav tabs>
            <NavItem className='erp-tabs'>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
              通知紀錄
          </NavLink>
        </NavItem>
        <NavItem className='erp-tabs'>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            登入紀錄
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ErpNotifyList update= {update}/> 
        </TabPane>
        <TabPane tabId="2">
          <ErpLogList update= {update} />
        </TabPane>
      </TabContent>
      </Layout>
      </ErpProvider>
  );
}

/* ERP 使用者登入異常通知紀錄 */
const ErpNotifyList = (props:any) => {
  //由上層帶入資料
  const { update } = props;
  const { state, dispatch } = useContext(ErpContext);
  const notifyList = state.notifyList;

  // const Dispatch = useCallback(dispatch, []);  // dispatch 需要使用useCallback 建立依賴的關係
   
  useEffect( () => {
    // 取得通知紀錄
    const getNotifyData = async () => {
      const result = await fetchNotifyData();
      if(result.status === 200) {
        dispatch({ type: "GET_ERP_NOTIFY_LIST", payload: result.data });
      }
    }
    getNotifyData();
  },[update, dispatch]);  //dispatch 為外部方法，須建立相依性。

  return(
    <Table striped size="sm">
    <thead>
      <tr>
        <th>裝置名稱</th>
        <th>工號</th>
        <th>使用者名稱</th>
        <th>單位</th>
        <th>登入時間</th>
        <th>類型</th>
        <th>讀取狀態</th>
      </tr>
    </thead>
    <tbody>
      { notifyList ? 
        notifyList.map((i:any) =>
          <tr key={i.ID}>
            <td>{i.DEVICE_NAME}</td>
            <td>{i.EMPLOYEE_ID}</td>
            <td>{i.EMPLOYEE_NAME}</td>
            <td>{i.DEPARTMENT}</td>
            <td>{i.ONLINE_TIME.split('.')[0]}</td>
            <td>{(i.TYPE)?<Badge color='primary'>ERP</Badge>:<Badge color='success'>DEVICE</Badge>}</td>
            <td>{(i.IS_NOTIFY)?<Badge color='success'>已通知</Badge>:<Badge color='warning'>未通知</Badge>}</td>
          </tr>
        ) :
         <tr>
           <td colSpan={7}><Alert color="success">無ERP通知資料</Alert></td>
         </tr> 
      }
    </tbody>
  </Table>
  );
}

/* ERP 使用者登入紀錄 */
const ErpLogList = (props:any) => {
  //由上層帶入資料
  const { update } = props;
  const { state, dispatch } = useContext(ErpContext);
  const logList = state.logList;

  // const Dispatch = useCallback(dispatch, []);

  useEffect( () => {
    // 取得上線紀錄
    const getLogData = async () => {
      const result = await fetchLogData();
      if(result.status === 200) {
        dispatch({ type: "GET_ERP_LOG_LIST", payload: result.data });
      }
    }
    getLogData();
  },[update, dispatch]);

  return(
    <Table striped size="sm">
    <thead>
      <tr>
        <th>裝置名稱</th>
        <th>工號</th>
        <th>使用者名稱</th>
        <th>單位</th>
        <th>登入時間</th>
        <th>讀取狀態</th>
      </tr>
    </thead>
    <tbody>
      { logList ? 
        logList.map((i:any, index:any) =>
          <tr key={index}>
            <td>{i.ComputerPC}</td>
            <td>{i.userId}</td>
            <td>{i.EmployeeName}</td>
            <td>{i.OrganizationUnitName}</td>
            <td>{i.LoginOutDateTime.split('.')[0]+"Z"}</td>
            <td>{(i.IsRead)?<Badge color='success'>已讀</Badge>:<Badge color='warning'>未讀</Badge>}</td>
          </tr>
        ) :
          <tr>
            <td colSpan={7}><Alert color="success">無ERP登入資料</Alert></td>
          </tr> 
       }
    </tbody>
   </Table>
  );
}

// export default Erp;
export default withRouter(Erp);