import React, { useCallback, useContext, useEffect, useReducer, createContext, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import styled from "styled-components";
// import { Redirect } from 'react-router-dom';
import Layout from '../components/Layout';
import { EmployeesContext, EmployeesProvider } from '../contexts';
import { useTranslation } from 'react-i18next';
import { fetchEmployeeAccount } from '../contexts/fetchAction';

const Span = styled.span.attrs(() => ({
  className: "badge badge-primary"
}))`  
`;

/* 定義按鈕樣式 */
const color = {
  success: 'btn-sm btn btn-success',
  warning: 'btn-sm btn btn-warning',
  secondary: 'btn-sm btn btn-secondary'
}

const Button = styled.button.attrs((props) => ({
  className: props.className
}))`  
`;

const Table = styled.table.attrs(() => ({
  className: "table table-bordered"
}))` 
  width: 500px;
`;

const Employees = () => {
  const { state } = useContext(EmployeesContext)
  const { pathname } = useLocation()
  const [ employeeId, setEmployeeId] = useState('');
  const [ employeeName, setEmployeeName] = useState('');
  const [ accountList, setAccontList] = useState([]);
  // console.log(pathname);
  const { t } = useTranslation();

  const handleIdChange = (e:any) => {
    // const event.value; 
    setEmployeeId(e.target.value)
  }

  const handleNameChange = (e:any) => {
    // const event.value; 
    setEmployeeName(e.target.value)
  }

  const search = async (event:any) => {
    event.preventDefault();
    
    if(employeeId == '' && employeeName == '')
    {
      alert('請輸入搜尋關鍵字');
      return;
    }
    
    const parameter = {
      'employeeId': employeeId,
      'employeeName': employeeName
    }
    console.log(JSON.stringify(parameter));
    //呼叫API
    const result = await fetchEmployeeAccount(parameter);
    if(result.status === 200) {
      if(result.total > 0 ) {
        setAccontList(result.data);
      }else {
        alert(result.msg);
      }

    } else {
      alert(result.msg);
    }  
  }


  return (
      <EmployeesProvider>
        <Layout>
          <h2>{t("employees.title")}</h2>
          <hr />
          
          <form className="row g-3">
            <div className="form-group col-md-6">
              <label htmlFor="employeeId">員工帳號</label>
              <input type="text" className="form-control" onChange= { handleIdChange } value={ employeeId }  placeholder="請輸入員工帳號" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="employeeName">員工姓名</label>
              <input type="text" className="form-control" onChange= { handleNameChange } value={ employeeName }  placeholder="請輸入員工姓名" />
            </div>
            <div className="form-group col-12">
              <button className="btn btn-success" type="button" onClick={search}>查詢</button>
            </div>
            {/* <span>{JSON.stringify(accountList)}</span> */}
          </form>
          <Table>
            <thead>
                <tr>
                    <th scope="col">工號</th>
                    <th scope="col">姓名</th>
                    <th scope="col">系統</th>
                </tr>
            </thead>
            <tbody>
            { accountList && accountList.map((i:any) =>
               <tr id={i}>
                 <td>{i.EmployeeId}</td>
                 <td>{i.EmployeeName}</td>
                 <td>{i.System}</td>
               </tr>
                   )}
            </tbody>
        </Table>           
        </Layout>
      </EmployeesProvider>
  );
}

export default withRouter(Employees);