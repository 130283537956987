type ActionTypes =
  | { type: 'GET_UNIT_HOUR_LIST', payload: any }
  | { type: 'GET_UNIT_HOUR_DETAIL', payload: any }

 
type InitialStateType = {
  unitHourList: any;
  unitHourDetail: any;
}

/* Details Page Reducer */
export const UnitsReducer  = (state: InitialStateType , action: ActionTypes) => {
  console.log(action);
  switch (action.type) {
      case 'GET_UNIT_HOUR_LIST':         
          return {...state, unitHourList: action.payload };
      case 'GET_UNIT_HOUR_DETAIL':         
          return {...state, unitHourDetail: action.payload };
      default:
          return state;
  }
}



