import React, { useContext, useEffect, useState } from 'react';
import { NavLink as RRNavLink, withRouter, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row } from 'styled/common';
import { Alert, Progress, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import Layout from '../components/Layout';
import { UnitsContext, UnitsProvider } from 'contexts';
import styled from "styled-components";
import { fetchUnitHourList, fetchUnitHourDetail } from '../contexts/fetchAction';
// import { isTemplateExpression } from 'typescript';

const Col4 = styled.div.attrs(() => ({
  className: 'col-lg col-lg-4',
}))`
`;

const Col3 = styled.div.attrs(() => ({
  className: 'col-lg col-lg-3',
}))`
  margin-bottom: 20px;
`;

const Span16 = styled.span.attrs(() => ({
  className: 'float-right'
}))`
    font-size: 16px;
`;

const Span24 = styled.span`
    font-size: 24px;
    font-weight: bold;
`;

const Td = styled.td.attrs(() => ({
  className: 'text-center'
}))`
`;


const Units = () => {
  const { state, dispatch } = useContext(UnitsContext);
  const { unitHourDetail } = state;
  const [ hourList, setHourList ] = useState([]);

  useEffect( () => {
    //取得資訊部對各單位工時服務清單
    const getUnitHourList = async () => {
       const result = await fetchUnitHourList();
       console.log(result);
       if(result.status === 200) {
          dispatch({ type: "GET_UNIT_HOUR_LIST", payload: result.data });
          setHourList(result.data);

       }
    }
    getUnitHourList();

  },[dispatch]);
  const { t } = useTranslation();
  return (    
    <UnitsProvider>
    <Layout>
      <h2>{t("units.title")}</h2>
      <hr />
      <Row>
      { hourList && hourList.map((item:any, index:any) =>
        <Col3 key={index} >
          <Card
          body
            style={{
            backgroundColor: '#ccc',
            borderColor: '#999'
          }}
        >
            <CardBody>
              <CardTitle tag="h5">
                {item.Unit_Tag_LEFT}                
                <Span16>
                  <Detail companyID= {item.Unit_Tag_LEFT} title= {item.HourTotal + ' / ' + item.HourLimit} />
                </Span16>               
              </CardTitle>
               <Span24>{item.HourPercent} %</Span24> 
              <ProgressBar value={item.HourPercent} />
            </CardBody>
          </Card>
        </Col3>
      )}
      </Row>
    </Layout>
    </UnitsProvider>
  )
}

//單位工時占用百分比
const ProgressBar = (props:any) => {
  const {
    value
  } = props;

  const [ color, setColor ] = useState('primary');

  useEffect( () => {
    if(value > 100 )
      setColor('danger')
    else if (value > 50 )
      setColor('warning')
    else
      setColor('success')
    
  },[value])

  return(
    <Progress color={color} value={value}></Progress>
  )
}

/**
 * 顯示單位工時明細
 */ 
 const Detail = (props:any) => {
  const {
    companyID,
    title
  } = props;
  
  const { state, dispatch } = useContext(UnitsContext);
  const { unitHourDetail } = state;
  const [ detail, setDetail ] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect( () => {

  },[companyID])

  const toggle = async () => {
    setModal(!modal);
    // console.log(modal);
  }

  const getHourDeail = async () => {
    setModal(!modal)
    
    //取得資訊部對各單位工時服務清單
    const parameter = {
      'companyID': companyID
    };
    // console.log(parameter);
    const result = await fetchUnitHourDetail(parameter);
    // console.log(result);
    if(result.status === 200) {
      dispatch({ type: "GET_UNIT_HOUR_DETAIL", payload: result.data });
      setDetail(result.data);
    }
  }

  return (
    <span className='float-right'>
      <Button color="secondary" size='sm' onClick={getHourDeail}>{title}</Button>
        <Modal size='lg' isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>工時明細</ModalHeader>
          <ModalBody>
              <Table bordered>
                 <tr>
                    <Td width={'20%'}>工時</Td>
                    <Td>需求主題</Td>
                 </tr>
                { unitHourDetail && unitHourDetail.map((item:any, index:any) =>
                  <tr key={index}>
                    <Td width={'20%'}>{item.txtActualHours}小時</Td>
                    <td>{item.txtRequestSubject}</td>
                  </tr>
                )}
              </Table>
          </ModalBody>
        </Modal>
    </span>
  );
}

export default withRouter(Units);

