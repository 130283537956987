type ActionTypes =
  | { type: 'RESET' }
  | { type: 'INCREMENT' }
  | { type: 'DECRMENT' }

type InitalServicesStateType = {
count: number;
counter_title: string;
color: string;
}

/* ERP Page Reducer */
export const ServicesReducer = (state: InitalServicesStateType , action: ActionTypes) => {
  console.log(action);
  switch (action.type) {
      case 'RESET':
          return { ...state, count: 0 };
      case 'INCREMENT':
          return { ...state, count: state.count + 1 };
      case 'DECRMENT':
          return { ...state, count: state.count - 1 };
      default:
          throw new Error('Unexpected action');
  }
} 
