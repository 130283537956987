import React, { Component, useReducer, createContext, useEffect } from 'react';
import { DashboardReducer } from 'reducers/dashboard';
import { DevicesReducer } from 'reducers/devices';
import { ErpReducer } from 'reducers/erp';
import { ServicesReducer } from 'reducers/services';
import { EmployeesReducer } from 'reducers/employees';
import { PersonsReducer } from 'reducers/persons';
import { ContractsReducer } from 'reducers/contracts';
import { UnitsReducer } from 'reducers/units';


/* 初始化狀態 */
const InitState = {
    title: {
        dashboard: "Dashboard",
        game: "Game",
        tables: "Tables",
        forms: "Forms",
        login: "MSit Admin",
        device: "Devices",
        erp: "ERP Notify",
        services: "Services"
    },
    info: {
        username: "admin",
        password: "msit"
    },
    color: "danger",
    toggle: false,
    count: 0      
}

const IndexInitSatae = {
    isLogin: false
}

/* 標題 */
// type TitleType = {
//     dashboard: string,
//     game: string,
//     tables: string,
//     forms: string,
//     login: string,
//     device: string,
//     erp: string,
//     services: string
// }

// const InitTitleState = {
//     dashboard: "Dashboard",
//     game: "Game",
//     tables: "Tables",
//     forms: "Forms",
//     login: "MSit Admin",
//     device: "Devices",
//     erp: "ERP Notify",
//     services: "Services"
// }


/*
 * Index Page (Index.tsx)
 * 
 */
type IndexState = Readonly<typeof IndexInitSatae>;

export const IndexContext = createContext(IndexInitSatae); // React 16.3 support
export class IndexProvider extends Component<{}, IndexState> {
    readonly state: IndexState = IndexInitSatae;
    render() {
        return(
            <IndexContext.Provider value={this.state}>
                {this.props.children}
            </IndexContext.Provider>
        )
    }
}

/*
 * Dashboard Page (Dashboard.tsx)
 * Description: 導入 useReducer
 * Update: 2020-10-13
 */
const initDashboardState = {
    departments: [],
    total: 0,
    todoHour: [],
    closedHour: [],
    todoHourTotal: [],
    caseList: [],
    allHourTotal: []
  }

const dashboardDispatch: React.Dispatch<any> = () => {} // we never actually use this (initServicesState)

export const DashboardContext = createContext({
  state: initDashboardState,
  dispatch: dashboardDispatch, // just to mock out the dispatch type and make it not optioanl
})

export const DashboardProvider = (props:any) => {
    const [state, dispatch ] = useReducer( DashboardReducer, initDashboardState);
    return(
        <DashboardContext.Provider value={{ state , dispatch }}>
           {props.children}
       </DashboardContext.Provider>
     )
}

/*
 * Devices Page (Devices.tsx)
 * Description: 導入 useReducer
 * Update: 2020-10-14
 */
const InitDeviceListState = [
    // {ID: 0, NAME: "1180250", USER: '萬致泰', OWNER: 'YES', DEPARTMENT_NAME: 'Tainan', STATUS: 0, CREATEDATE: 'TOYOTA'}];
    {
      id: 110,
      computer_name: 'ITADMPC01',
      owner_id: "1180250",
      owner_name: "萬致泰",
      department_id: "D6",
      department_name: "資訊部",
      status: 0,
      create_date: "2020-05-23",
      remark: ""
    }];

const initDevicesState = {
    deviceList: InitDeviceListState,
    departmentList: [],
    total: 800
}

const devicesDispatch: React.Dispatch<any> = () => {} // we never actually use this (initServicesState)

export const DevicesContext = createContext({
  state: initDevicesState,
  dispatch: devicesDispatch, // just to mock out the dispatch type and make it not optioanl
})

export const DevicesProvider = (props:any) => {
    const [state, dispatch ] = useReducer( DevicesReducer, initDevicesState);
    return(
        <DevicesContext.Provider value={{ state, dispatch }}>
            {props.children}
        </DevicesContext.Provider>
    )
}


/*
 * ERP Page (Erp.tsx)
 * Description: 導入 useReducer
 * Update: 2020-10-14
 */
const initErpState = {
    logList: [],
    notifyList: [],
    count: 0  
}


const erpDispatch: React.Dispatch<any> = () => null // we never actually use this (initErpState)

export const ErpContext = createContext({
  state: initErpState,
  dispatch: erpDispatch, // just to mock out the dispatch type and make it not optioanl
}) 

export const ErpProvider = (props:any) => {
    const [state, dispatch ] = useReducer( ErpReducer, initErpState);
    return(
        <ErpContext.Provider value={{ state , dispatch }}>
            {props.children}
        </ErpContext.Provider>
    )
}

/*
 * Services Page (Services.tsx)
 * Description: 導入 useReducer => 有問題
 * Update: 2020-10-14
 */
const initServicesState = {
    count: 0,
    counter_title: '計數器',
    color: 'danger'
    
}

const servicesDispatch: React.Dispatch<any> = () => {} // we never actually use this (initServicesState)

export const ServicesContext = createContext({
  state: initServicesState,
  dispatch: servicesDispatch, // just to mock out the dispatch type and make it not optioanl
})

export const ServicesProvider = (props:any) => {
    const [state, dispatch ] = useReducer( ServicesReducer, initServicesState);
    return(
        <ServicesContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ServicesContext.Provider>
    )
}


/*
 * Employees Page (Employees.tsx)
 * Description:
 * Update: 2020-10-14
 */
const initEmployeeState = {
    count: 0,
    counter_title: '計數器',
    color: 'danger'
    
}

const employeesDispatch: React.Dispatch<any> = () => {} // we never actually use this (initServicesState)

export const EmployeesContext = createContext({
  state: initEmployeeState,
  dispatch: employeesDispatch, // just to mock out the dispatch type and make it not optioanl
})

export const EmployeesProvider = (props:any) => {
    const [state, dispatch ] = useReducer( EmployeesReducer, initEmployeeState);
    return(
        <EmployeesContext.Provider value={{ state, dispatch }}>
            {props.children}
        </EmployeesContext.Provider>
    )
}

/*
 * Persons Page (Persons.tsx)
 * Description:
 * Update: 2021-12-17
 */
const initPersonsState = {
    monthList: [],
    hourList: []    
}

const personsDispatch: React.Dispatch<any> = () => {} // we never actually use this (initServicesState)

export const PersonsContext = createContext({
  state: initPersonsState,
  dispatch: personsDispatch, // just to mock out the dispatch type and make it not optioanl
})

export const PersonsProvider = (props:any) => {
    const [state, dispatch ] = useReducer( PersonsReducer, initPersonsState);
    return(
        <PersonsContext.Provider value={{ state, dispatch }}>
            {props.children}
        </PersonsContext.Provider>
    )
}

/*
 * Contract Page (Contract.tsx)
 * Description:
 * Update: 2021-12-26
 */
const initContractsState = {
    contractsList: []
}

const contractsDispatch: React.Dispatch<any> = () => {} // we never actually use this (initServicesState)

export const ContractsContext = createContext({
  state: initContractsState,
  dispatch: contractsDispatch, // just to mock out the dispatch type and make it not optioanl
})

export const ContractsProvider = (props:any) => {
    const [state, dispatch ] = useReducer( ContractsReducer, initContractsState);
    return(
        <ContractsContext.Provider value={{ state, dispatch }}>
            {props.children}
        </ContractsContext.Provider>
    )
}

/*
 * Deatail Page (Contract.tsx)
 * Description: 資訊部對各單位服務工時明細
 * Update: 2022-01-21
 */
const initUnitsState = {
    unitHourList: [],
    unitHourDetail: []
}

const unitsDispatch: React.Dispatch<any> = () => {} // we never actually use this (initServicesState)

export const UnitsContext = createContext({
  state: initUnitsState,
  dispatch: unitsDispatch, // just to mock out the dispatch type and make it not optioanl
})

export const UnitsProvider = (props:any) => {
    const [state, dispatch ] = useReducer( UnitsReducer, initUnitsState);
    return(
        <UnitsContext.Provider value={{ state, dispatch }}>
            {props.children}
        </UnitsContext.Provider>
    )
}


// ==============================================================



/*
 * Forms Page (Forms.tsx)
 * 
 */
type FormsState = Readonly<typeof InitState>;

export const FormsContext = createContext(InitState); // React 16.3 support
export class FormsProvider extends Component<{}, FormsState> {
    readonly state: FormsState = InitState;
    render() {
        return(
            <FormsContext.Provider value={this.state}>
                {this.props.children}
            </FormsContext.Provider>
        )
    }
}

/*
 * Login Page (Login.tsx)
 * 
 */

type LoginState = Readonly<typeof InitState>;

export const LoginContext = createContext(InitState); // React 16.3 support
export class LoginProvider extends Component<{}, LoginState> {
    readonly state: LoginState = InitState;

    render() {
        return(
            <LoginContext.Provider value={this.state}>
                {this.props.children}
            </LoginContext.Provider>
        )
    }
}



/*
 * Tables Page (Tables.tsx)
 * 
 */
type TablesState = Readonly<typeof InitState>;

export const TablesContext = createContext(InitState); // React 16.3 support
export class TablesProvider extends Component<{}, TablesState> {
    readonly state: TablesState = InitState;
    render() {
        return(
            <TablesContext.Provider value={this.state}>
                {this.props.children}
            </TablesContext.Provider>
        )
    }
}


/*
 * Docs Page
 *
 * 
 */
const InitDocsState = {
    docsTitle: "文件",
    // name:  firebase.firestore().collection('news').doc('Ff0SMGJTiBMG8RPcMtmf'),
    isLoading: true,
    lists: Array<InitDocsList>()
}

interface InitDocsList {
    id: number
    title: string
    created: string
}

type DocsState = Readonly<typeof InitDocsState>;

export const DocsContext = createContext(InitDocsState); // React 16.3 support

export class DocsProvider extends Component<{}, DocsState> {
    readonly state: DocsState = InitDocsState;


    // componentDidMount() {
    //     // var List = Array<InitDocsList>();
    //     var List = new Array(); 
    //     firebase.firestore().collection("news").get()
    //     .then((querySnapshot) => {
    //         if (querySnapshot) {
    //             querySnapshot.forEach((doc) => {
    //                 // console.log(`${doc.id} => ${doc.data()}`);
    //                 const { id, title, created } = doc.data();
    //                 List.push({
    //                     id: id,
    //                     title: title,
    //                     created: created
    //                 });
    //             });
    //             this.setState({
    //                 lists: List,
    //                 isLoading: false
    //             });
    //             // console.log('List =>', JSON.stringify(List));
    //         } else {
    //             console.log('找不到最新訊息!')
    //         }
    //     })
    //     .catch((err: Error) => console.log(err));        
    // }

    render() {
        console.log('List(render) =>' + JSON.stringify(this.state.lists));
        return(
            <DocsContext.Provider value={this.state}>
                {this.props.children}
            </DocsContext.Provider>
        )
    }
}


// export const StateProvider = ({reducer, initialState, children}) =>(
//     <StateContext.Provider value={useReducer(reducer, initialState)}>
//       {children}
//     </StateContext.Provider>
//   );
