type ActionTypes =
| { type: 'GET_CONTRACT_LIST', payload: any }
| { type: 'UPDATE_CONTRACT', payload: any }


type InitialStateType = {
  contractsList: any;
}

/* Persons Page Reducer */
export const ContractsReducer  = (state: InitialStateType , action: ActionTypes) => {
  console.log(action);
  switch (action.type) {
      case 'GET_CONTRACT_LIST':         
          return {...state, contractsList: action.payload };
      default:
          return state;
  }
}



