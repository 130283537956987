// 設定API主機URL
const Config = {
    //正式主機URL
    //production_url: 'http://192.168.6.30:10993/api',
    production_url: 'https://msit.manstrong.com.tw/api',
    //本機開發URL
    //develop_url: 'https://msit.manstrong.com.tw/api'
    develop_url: 'http://localhost:10933/api'
}

export default Config;