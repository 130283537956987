import React, { useState, useEffect } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, ModalFooter, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignInAlt, faTachometerAlt, faGamepad, faTable, faList, faBars , faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button
 } from 'reactstrap';
 import { fetchApiVersion } from '../contexts/fetchAction';
import Login from '../pages/Login';
import info from "../../package.json";
import { device } from "../styled/device";
import { useTranslation } from 'react-i18next';
import './Styles.css';


const Span = styled.span`
    margin-left: 8px;
    margin-right: 8px;
`;

//desktop 解析度大於1440px 顯示左邊導覽選單(預設: margin-left: -250px)
const ToggleMenu = styled.div`    
    @media ${device.desktop} {
      position: fixed;
      margin-left: 250px;
  }
`;

//右上角使用者導覽按鈕顯示(大於1440px顯示)
const UserNav = styled.div.attrs((props) => ({
  // className: "badge badge-primary"
  className: props.className
}))`
  //預設不顯示
  display: none;
  
  //desktop解析度大於1440px 顯示此按鈕(min-width: 1440px)
  @media ${device.desktop} {
    display: block;
  }
`;

const Header = (props:any) => {

  const {
    isOpen,
    setIsOpen
  } = props;
    //const [isOpen, setIsOpen] = React.useState(false);// The useState hook
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [user, setUser] = useState('');
    const [modal, setModal] = useState(false); //控制版本資訊Modal
    const { t } = useTranslation();

    useEffect( () => {
      const username = sessionStorage.username || '';
      setUser(username);
    },[user]);

    const toggle = () => {
      setIsOpen(!isOpen);
    }

    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

    const handleModal = () => setModal(!modal);

    const loginOutClick = () => {
      alert("你已經登出了");
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('isLogin');
      sessionStorage.removeItem('expired');
      sessionStorage.removeItem('menu');
      //直接重新整理
      window.location.reload();
    }

    return (
        <>
          <Navbar color="dark" dark expand="sm" fixed="top">

          <NavbarBrand tag={Link} to="/">
              {/* <img src={process.env.PUBLIC_URL + '/Ocean.png'} width="32px" height="32px" alt="Logo" />{'    '} */}
              MSit Admin
          </NavbarBrand>
              <ToggleMenu>
                <button type="button" id="sidebarCollapse" className="btn btn-secondary" onClick={toggle}>
                  <FontAwesomeIcon icon={faBars} /> 
                </button>
              </ToggleMenu>
          {/* <Collapse navbar> */}
            <UserNav className="nav ml-auto nav-info">
            {/* 下拉選單 */ }
            <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
                <DropdownToggle caret>
                  {/* <NavLink tag={Link} to="#"> */}
                    <FontAwesomeIcon icon={faUser} />
                      <Span>{user}</Span>
                  {/* </NavLink> */}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick= {handleModal}>
                    <VersionInfo title= {t('header.version')} modal = {modal} setModal = {setModal} />
                  </DropdownItem>
                  <DropdownItem divider />
                  {/* <DropdownItem> */}
                  { sessionStorage.isLogin === 'false' ?
                        <DropdownItem tag={Link} to="/login">
                          <FontAwesomeIcon icon={faSignInAlt} />
                          <Span>{t('header.login')}</Span>
                        </DropdownItem>
                        :
                        <DropdownItem tag={Link} to="#" onClick= {loginOutClick}>
                           <FontAwesomeIcon icon={faSignInAlt} />
                           <Span>{t('header.logout')}</Span>
                        </DropdownItem>
                  }
                  {/* </DropdownItem> */}
                </DropdownMenu>
             </InputGroupButtonDropdown>
              {/* <NavItem>
                <NavLink href="https://github.com/mytime23/ord-web">GitHub</NavLink>
              </NavItem> */}
            </UserNav>
          {/* </Collapse> */}
        </Navbar>
        </>
    );
}

/* 版本資訊 */
const VersionInfo = (props:any) => {
  const {
    title,
    modal,
    setModal
  } = props;

  const [ version, setVersion ] = useState('');

  useEffect( () => {
    //取得人員每月工時與案件工時
    const getApiVersion = async () => {
       const result = await fetchApiVersion();
       console.log(result);
       if(result.status === 200) {
          setVersion(result.data.version);
          // dispatch({ type: "GET_DEVICE_TOTAL", payload: result.total });
       }
    }
    getApiVersion();

  },[modal]);

  const MODE = process.env.MODE;  
  const deviceInfo = window.navigator.userAgent;
  // Get the size of the device screen
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  
  // Get the browser window size
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const { t } = useTranslation();

  const toggle = () => {
    setModal(!modal);
    // console.log(`model : ${modal}`);
  } 
  return (
    <div>
        <FontAwesomeIcon icon={faInfoCircle} /><span className="icon-left">{t('header.version')}</span>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody>
            <p>Project: <Button color="success">{info.name}</Button></p>
            <p>Version: <Button color="primary">v{info.version}</Button></p>
            <p>API Version: <Button color="warning">v{version}</Button></p>
            <p>Mode: { MODE} </p>
            <p>DeviceInfo: { deviceInfo} </p>
            <p>screen: {screenWidth} X {screenHeight} </p>
            <p>window: {windowWidth} X {windowHeight} </p>
          </ModalBody>
          {/* <ModalFooter>
              <Button color="primary" onClick={toggle}>確認</Button>{' '}
          </ModalFooter> */}
        </Modal>
    </div>
  );
}

export default withRouter(Header);