import { NavLink as RRNavLink, withRouter, matchPath, useLocation } from 'react-router-dom';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const DivNav = styled.div.attrs(() => {
})` 
  margin-bottom: 15px;
`;

const NavLi = styled.li.attrs((props) =>{
})`
  a {
    padding: 10px;
    font-size: 1.0em;
    display: block;
    ${props => (props.state ? `
      background-color: #4189C7;
      color: white;
      `: ``)};
  }
  
  a:hover {
    color: #7386D5;
    background: #fff;
  }
`;

const ServicesNav = () => {
  return (
    <DivNav>
      <Nav>
        <NavLi state={ matchPath(window.location.pathname, { path: "/manage" }) ? true : false}>
          <NavLink tag={RRNavLink} to="/manage">伺服器管理</NavLink>
        </NavLi>
        <NavLi state={ matchPath(window.location.pathname, { path: "/services" }) ? true : false}>
          <NavLink tag={RRNavLink} to="/services">伺服器狀態</NavLink>
        </NavLi>
      </Nav>
    </DivNav>

  );
} 

export default ServicesNav;