import React, { useCallback, useContext, useEffect, useReducer, createContext, useState } from 'react';
import { NavLink as RRNavLink, withRouter, matchPath, useLocation } from 'react-router-dom';
import { Row, Col, Table, Card, CardHeader, CardBody, Alert, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Input, CustomInput } from 'reactstrap';
import styled from "styled-components";
import Layout from '../components/Layout';
import { ServicesContext, ServicesProvider } from '../contexts';
import { fetchServerList, fetchServerState, fetchCreateServer, fetchUpdateServer } from 'contexts/fetchAction';
import { useTranslation } from 'react-i18next';
import ServicesNav from '../components/services-nav';

const DivModal = styled.div.attrs(() => {
})` 
  margin-bottom: 15px;
`;

const DivCard = styled.div.attrs(() => {
})` 
  margin-bottom: 25px;
`; 

const Span = styled.span.attrs((props) => ({
  // className: "badge badge-primary"
  className: props.className
}))`
  text-align: right;  
`;

/* 定義狀態樣式 */
const badgeColor = {
  primary: 'badge badge-primary',
  success: 'badge badge-success',
  warning: 'badge badge-warning',
  secondary: 'badge badge-secondary',
  danger: 'badge badge-danger',
}

/**
 * 伺服器管理 
 */ 
const ServicesManage = () => {
  const [ update, setUpdate ] = useState(false);

  return (
    <ServicesProvider>
      <Layout>
      <ServicesNav />
        {/* <h2>伺服器管理</h2> */}
        <hr />
        <Row>
          <Col >
          <AddServer buttonLabel= "新增伺服器" className= "default-font" setUpdate= {setUpdate} update= {update} />
          </Col>
        </Row>
        <List setUpdate= {setUpdate} update= {update}/>         
      </Layout>
    </ServicesProvider>
  );
}

interface Server {
  server_number: string,
  server_name: string,
  server_description: string,
  server_ip: string,
  is_online: number,
  server_position: string,
  server_url: string,
  server_remark: string,
  is_enable: number,
}

const List = (props:any) => {
  //由上層帶入資料
  const { update, setUpdate } = props;
  const [serverList, setServerList] = useState([]);
  const [detail, setDetail] = useState<Server>();

  useEffect( () => {
    //取得各部門狀態數量
    const getServerList = async () => {
       const result = await fetchServerList();
       console.log(result);
       if(result.status === 200) {
          // dispatch({ type: "GET_DEPARTMENT_LIST", payload: result.data });
          // dispatch({ type: "GET_DEVICE_TOTAL", payload: result.total });
          setServerList(result.data);
       }
    }
    getServerList();

  },[update]);
  
  return(
      <div>
        <Row>  
          { serverList && serverList.map((i:any) =>
            <Col sm="3">
              <DivCard>
              <Card>
              <CardHeader>
                 <h5>{i.server_name}
                              <span className="float-right">{(i.is_online ? 
                                <Span className={ badgeColor.success }>使用中</Span> :
                                <Span className={ badgeColor.danger }>已停用</Span>)}</span>
                 </h5>
              </CardHeader>
              <CardBody>
                {/* <FontAwesomeIcon icon={faLaptop} /> */}
                {i.server_description}                               
                <p>URL: {i.server_url}</p>
                <p>IP: {i.server_ip}</p>
                <p>位置: {i.server_position}</p>
                <p>更新日期: {i.modified_date}</p>
                <Alert color="info">備註說明:<hr/>{i.server_remark}</Alert>

                {(i.is_enable ? <Span className={ badgeColor.warning }>偵測中</Span> : '')}
                <EditServer buttonLabel= "修改" className= "default-font" setUpdate= {setUpdate} update= {update} detail= {i} />
              </CardBody>
            </Card>              
              </DivCard>         
            </Col>
            )} 
      </Row>
    </div>
  );
}

/**
 * 新增伺服器
 */ 
const AddServer = (props:any) => {
  const {
    buttonLabel,
    className,   //由上層帶入class
    setUpdate,
    update
  } = props;

  const [modal, setModal] = useState(false);

  const [SN, setSN] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isEnable, setIsEnable] = useState(false);
  const [IP, setIP] = useState('');
  const [position, setPosition] = useState('');
  const [URL, setURL] = useState('');
  const [remark, setRemark] = useState('');
  const [isOnline, setIsOnline] = useState(false);

  const toggle = () => setModal(!modal);
  const changeSN = (event:any) => setSN(event.target.value);
  const changeName = (event:any) => setName(event.target.value);
  const changeDescription = (event:any) => setDescription(event.target.value);
  const changeIP = (event:any) => setIP(event.target.value);
  const changePosition = (event:any) => setPosition(event.target.value);
  const changeURL = (event:any) => setURL(event.target.value);
  const changeRemark = (event:any) => setRemark(event.target.value);
  
  //是否啟用伺服器主機
  const changeIsOnline = (event:any) => {
    let checked = false;   
    checked = event.target.checked ? true : false;
    setIsOnline(checked);
  } 

  //是否啟用異常偵測
  const changeIsEnable = (event:any) => {
    let checked = false;   
    checked = event.target.checked ? true : false;
    setIsEnable(checked);
  }
  
  // 加入新的伺服器主機
  const createServer = async (event:any) => {
    event.preventDefault();     
    const parameter = {
        'server_number': SN,
        'server_name': name,
        'server_description': description,
        'server_ip': IP,
        'is_online': isOnline ? 1 : 0 , //是否上線  
        'server_position': position,
        'server_url': URL,
        'server_remark': remark,
        'is_enable': isEnable ? 1 : 0
      }


      console.log(JSON.stringify(parameter));
      //呼叫API
      const result = await fetchCreateServer(parameter);
      if(result.status === 200) {
        alert(result.msg);
        setUpdate(!update);
        toggle();
      } else {
        alert(result.msg);
      }      
  }

  return (
    <DivModal>
      <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>新增伺服器</ModalHeader>
          <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="serverSN" sm={3}>代號</Label>
              <Col sm={9}>
                <Input type="text" name="serverSN" id="serverSN" value= {SN} onChange={ changeSN} placeholder="請輸入伺服器代碼" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverName" sm={3}>伺服器名稱</Label>
              <Col sm={9}>
                <Input type="text" name="serverName" id="serverName" value= {name} onChange={ changeName} placeholder="請輸入伺服器名稱(例: Server01)" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverDescription" sm={3}>伺服器說明</Label>
              <Col sm={9}>
                <Input type="text" name="serverDescription" id="serverDescription" value= {description} onChange={ changeDescription} placeholder="請輸入伺服器說明" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverIP" sm={3}>伺服器 IP</Label>
              <Col sm={9}>
                <Input type="text" name="serverIP" id="serverIP" value= {IP} onChange={ changeIP} placeholder="請輸入伺服器IP位址" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={3}></Col>
              <Col sm={9}>
                  <CustomInput type="switch" id="isOnline" name="isOnline" label="是否啟用伺服器" onChange={changeIsOnline} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverPosition" sm={3}>伺服器位置</Label>
              <Col sm={9}>
                <Input type="text" name="serverPosition" id="serverPosition" value= {position} onChange={ changePosition} placeholder="請輸入伺服器所在位置" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverURL" sm={3}>URL網址</Label>
              <Col sm={9}>
                <Input type="text" name="serverURL" id="serverURL" value= {URL} onChange={ changeURL} placeholder="請輸入伺服器網址" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="remark" sm={3}>詳細說明</Label>
              <Col sm={9}>
                <Input type="textarea" name="remark" id="remark" value= {remark} onChange={ changeRemark} placeholder="詳細說明" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={3}></Col>
              <Col sm={9}>
                <CustomInput type="switch" id="isEnable" name="isEnable" label="是否偵測異常" onChange={changeIsEnable} />
              </Col>
            </FormGroup>
          </Form>  
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={createServer}>確認</Button>{' '}
              <Button color="secondary" onClick={toggle}>取消</Button>
          </ModalFooter>
        </Modal>
    </DivModal>
  );
}

/**
 *  修改伺服器
 */ 
const EditServer = (props:any) => {
  const {
    buttonLabel,
    className,
    setUpdate,
    update,
    detail
  } = props;

  const [modal, setModal] = useState(false);

  const [number, setNumber] = useState(detail.server_number);
  const [name, setName] = useState(detail.server_name);
  const [description, setDescription] = useState(detail.server_description);
  const [isOnline, setIsOnline] = useState(detail.is_online);
  const [ip, setIp] = useState(detail.server_ip);
  const [position, setPosition] = useState(detail.server_position);
  const [url, setUrl] = useState(detail.server_url);
  const [remark, setRemark] = useState(detail.server_remark ? detail.server_remark : '');
  const [isEnable, setIsEnable] = useState(detail.is_enable);

  useEffect( () => {

    //是否啟用伺服器主機
    let online = false;   
    online = isOnline ? true : false; // eslint-disable-next-line
    setIsOnline(online);
  
    //是否啟用異常偵測
    let enable = false;   
    enable = isEnable ? true : false; // eslint-disable-next-line
    setIsEnable(enable);

  },[update, isOnline, isEnable]);

  const toggle = () => { 
    setModal(!modal);
    //當關閉修改對話框時，將資料回復到上層帶入的資料
    setName(detail.server_name);
    setDescription(detail.server_description);
    setIp(detail.server_ip);
    setIsOnline(detail.is_online);
    setPosition(detail.server_position);
    setUrl(detail.server_url);
    setRemark(detail.server_remark ? detail.server_remark : '');
    setIsEnable(detail.is_enable);    
  };
  const changeName = (event:any) => setName(event.target.value);
  const changeDescription = (event:any) => setDescription(event.target.value);
  const changeIp = (event:any) => setIp(event.target.value);
  const changePosition = (event:any) => setPosition(event.target.value);
  const changeUrl = (event:any) => setUrl(event.target.value);
  const changeRemark = (event:any) => setRemark(event.target.value);
  
  //是否啟用伺服器主機
  const changeIsOnline = (event:any) => {
    let checked = false;   
    checked = event.target.checked ? true : false;
    setIsOnline(checked);
  } 

  //是否啟用異常偵測
  const changeIsEnable = (event:any) => {
    let checked = false;   
    checked = event.target.checked ? true : false;
    setIsEnable(checked);
  }

  //更新伺服器主機
  const updateServer = async (event:any) => {
    event.preventDefault();     
    const parameter = {
        'server_number': number,
        'server_name': name,
        'server_description': description,
        'server_ip': ip,
        'is_online': isOnline ? 1 : 0 , //是否上線  
        'server_position': position,
        'server_url': url,
        'server_remark': remark,
        'is_enable': isEnable ? 1 : 0
      }


      console.log(JSON.stringify(parameter));
      //呼叫API
      const result = await fetchUpdateServer(parameter);
      if(result.status === 200) {
        setUpdate(!update);
        alert(result.msg);
        toggle();
      } else {
        alert(result.msg);
      }      
  }

  return (
    <div className="float-right">
      <Button size="sm" color="primary" onClick={toggle}>{buttonLabel}</Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>修改伺服器主機</ModalHeader>
          <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="serverSN" sm={3}>代號</Label>
              <Col sm={9}>
                <Input type="text" name="serverSN" id="serverSN" value= {number} disabled/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverName" sm={3}>伺服器名稱</Label>
              <Col sm={9}>
                <Input type="text" name="serverName" id="serverName" value= {name} onChange={ changeName} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverDescription" sm={3}>伺服器說明</Label>
              <Col sm={9}>
                <Input type="text" name="serverDescription" id="serverDescription" value= {description} onChange={ changeDescription} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverIP" sm={3}>伺服器 IP</Label>
              <Col sm={9}>
                <Input type="text" name="serverIP" id="serverIP" value= {ip} onChange={ changeIp} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={3}></Col>
              <Col sm={9}>
                  <CustomInput type="switch" id="isOnline" name="isOnline" label="是否啟用伺服器" onChange={changeIsOnline} checked={ isOnline ? true : false } />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverPosition" sm={3}>伺服器位置</Label>
              <Col sm={9}>
                <Input type="text" name="serverPosition" id="serverPosition" value= {position} onChange={ changePosition} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="serverURL" sm={3}>URL網址</Label>
              <Col sm={9}>
                <Input type="text" name="serverURL" id="serverURL" value= {url} onChange={ changeUrl} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="remark" sm={3}>詳細說明</Label>
              <Col sm={9}>
                <Input type="textarea" name="remark" id="remark" value= {remark} onChange={ changeRemark} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={3}></Col>
              <Col sm={9}>
                <CustomInput type="switch" id="isEnable" name="isEnable" label="是否偵測異常" onChange={changeIsEnable} checked={isEnable ? true : false } />
              </Col>
            </FormGroup>
          </Form>  
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={updateServer}>確認</Button>{' '}
              <Button color="secondary" onClick={toggle}>取消</Button>
          </ModalFooter>
        </Modal>
    </div>
  );
}

export default withRouter(ServicesManage);