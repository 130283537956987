import React, { useCallback, useContext, useEffect, useReducer, createContext, useState } from 'react';
import { NavLink as RRNavLink, withRouter, matchPath, useLocation } from 'react-router-dom';
import { Row, Col,Nav, NavItem, NavLink } from 'reactstrap';
import styled from "styled-components";
import Layout from '../components/Layout';
import { ServicesContext, ServicesProvider } from '../contexts';
import { fetchServerList, fetchServerState } from 'contexts/fetchAction';
import ServicesNav from '../components/services-nav';
import { useTranslation } from 'react-i18next';


const Span = styled.span.attrs((props) => ({
  // className: "badge badge-primary"
  className: props.className
}))`  
`;

/* 定義狀態樣式 */
const badgeColor = {
  primary: 'badge badge-primary',
  success: 'badge badge-success',
  warning: 'badge badge-warning',
  secondary: 'badge badge-secondary',
  danger: 'badge badge-danger',
}

/* 定義按鈕樣式 */
const color = {
  success: 'btn-sm btn btn-success',
  warning: 'btn-sm btn btn-warning',
  secondary: 'btn-sm btn btn-secondary'
}

const Button = styled.button.attrs((props) => ({
  className: props.className
}))`  
`;

const Table = styled.table.attrs(() => ({
  className: "table table-bordered"
}))` 
  width: 960px;
`;

// const Nav = styled.div`
//   margin-left: 10px;
//   margin-bottom: 10px;
// `;

// const MODE = process.env.MODE;
/**
 * 伺服器狀態 
 */ 
const Services = () => {
  const { state } = useContext(ServicesContext)
  const { pathname } = useLocation()
  const [ update, setUpdate ] = useState(getNowFormatDate());
  // console.log(pathname);
  const { t } = useTranslation();

    //取得目前時間
    function getNowFormatDate(dt = '') {
      var date:any = '';
      if(dt) {
          date = dt;
      }else {
          date = new Date();
      }
      // var date = (dt?new Date():dt);
      // var date = new Date();
      var seperator1 = "-";
      var seperator2 = ":";
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
          + " " + date.getHours() + seperator2 + date.getMinutes()
          + seperator2 + date.getSeconds();
      return currentdate;
  }

  useEffect( () => {
    let id = setInterval(() => {
      setUpdate(getNowFormatDate());
    },10000) //10秒更新一次
    return () => clearInterval(id);
  },[update]);  //更新的變量，重新Render


  return (
      <ServicesProvider>
        <Layout>
          <ServicesNav />
          {/* <h2>{t("services.title")}</h2> */}
          <hr />        
          {/* <Counter /> */}
           <div className="text-right">
            最後更新時間: {update}
          </div>
          <List update= {update}/>
        </Layout>
      </ServicesProvider>
  );
}

const Counter = () => {
  const { state, dispatch } = useContext(ServicesContext)
  const { count } = state;

  // const Dispatch = useCallback(dispatch, []);  // dispatch 需要使用useCallback 建立依賴的關係
  
  // useEffect( () => {
  //   const fetchIncrement = async () => {
  //     Dispatch({ type: 'INCREMENT' });
  //   }
  //   fetchIncrement();
  // },[Dispatch]);

  return(
    <div>
        <Span className={ badgeColor.primary} data-testid="message">{count}</Span>
        <Button className={color.success} onClick={async () => dispatch({ type: 'INCREMENT' })}>增加</Button>{'        '}
        <Button className={color.warning} onClick={async () => dispatch({ type: 'DECRMENT' })}>減少</Button>{'        '}
        <Button className={color.secondary} onClick={async () => dispatch({ type: 'RESET' })}>重設</Button> 
    </div>
  )
}

const List = (props:any) => {
  //由上層帶入資料
  const { update } = props;
  const [serverList, setServerList] = useState([]);
  useEffect( () => {
    //取得各部門狀態數量
    const getServerList = async () => {
       const result = await fetchServerList();
       console.log(result);
       if(result.status === 200) {
          // dispatch({ type: "GET_DEPARTMENT_LIST", payload: result.data });
          // dispatch({ type: "GET_DEVICE_TOTAL", payload: result.total });
          // setServerList(result.data);
       }
    }

    const getServerStatus = async () => {
      const result = await fetchServerState();
      console.log(result);
      if(result.status === 200) {
         // dispatch({ type: "GET_DEPARTMENT_LIST", payload: result.data });
         // dispatch({ type: "GET_DEVICE_TOTAL", payload: result.total });
         setServerList(result.data);
      }
   }
    // getServerList();
    getServerStatus();
  },[update]);
  
  return(
      <div>
          <Table>
            <thead>
                <tr>
                    <th scope="col">伺服器名稱</th>
                    <th scope="col">伺服器說明</th>
                    <th scope="col">伺服器IP</th>
                    <th scope="col">系統狀態</th>
                    <th scope="col">更新時間</th>
                </tr>
            </thead>
            <tbody>
            { serverList && serverList.map((i:any) =>
               <tr key={i.server_number}>
                 <td>{i.server_name}</td>
                 <td>{i.server_description}</td>
                 <td>{i.server_ip}</td>
                 <td>{(i.server_status ? 
                       <Span className={ badgeColor.danger }>異常</Span> :
                       <Span className={ badgeColor.success }>正常</Span>)}
                 </td>
                 <td>{i.create_date}</td>
               </tr>
                   )}
            </tbody>
        </Table>  
      </div>
  );
}

// export default Services;
export default withRouter(Services);