import Config from 'configs/Config';
//取得使用模式URL
const API_URL = process.env.NODE_ENV === 'production' ? Config.production_url : Config.develop_url ;

/**
 * 登入驗證
 */
export const fetchLoginAuth = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/getAuth', {
    method: 'POST',
    //credentials: 'include',
    body: JSON.stringify(parameter),
    headers: {
      //'Content-Type': 'application/x-www-form-encoded'
      'Content-Type': 'application/json'
    }
  });
  return await response.json()
}

/**
 * 取得各部門裝置統計數量
 */
export const fetchDepartmentDeviceList = async ():Promise<any> => {
    let response = await fetch(API_URL + '/getDeviceDepartmentStatistics', {
      method: 'POST',
      body: '',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.accessToken
      }
    });
    return await response.json()
}

/**
 * 取得裝置電腦清單
 */
export const fetchDeviceData = async ():Promise<any> => {
  let response = await fetch(API_URL + '/getDeviceList', {
    method: 'POST',
    body: JSON.stringify({'count': 0}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得部門清單
 */
export const fetchDepartmentData = async ():Promise<any> => {  
  let response = await fetch(API_URL + '/getDepartmentList', {
    method: 'POST',
    body: JSON.stringify({'count': 25}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 更新裝置資料
 * parameter:
 *  id : 使用者編號,
 *  values : 更新內容 
 */
export const fetchUpdateDeviceData = async (id:any, values:any):Promise<any> => {
  // console.log("data: " + JSON.stringify(values[0]));
  // console.log("id: " + id[0]);
  //未修改內容則不進行呼叫API
  if(JSON.stringify(values[0]) !== undefined) {
    let response = await fetch(API_URL + '/updateDeviceList', {
      method: 'POST',
      body: JSON.stringify({'id': id[0], 'data': values[0] }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.accessToken
      }
    });
    return await response.json()
  }  
}

/**
 * 新增裝置
 * parameter: body
 */
export const fetchCreateDevice = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/createDevice', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得搜尋結果
 * parameter: body
 */
export const fetchSearchResult = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/searchDeviceList', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得ERP通知紀錄
 */
export const fetchNotifyData = async ():Promise<any> => {
  let response = await fetch(API_URL + '/getNotifyList', {
    method: 'POST',
    body: JSON.stringify({'count': 30}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得ERP上線紀錄
 */
export const fetchLogData = async():Promise<any> => {
  let response = await fetch(API_URL + '/getList', {
    method: 'POST',
    body: JSON.stringify({'count': 30}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得員工帳號
 */
export const fetchEmployeeAccount = async(parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/getEmployeeAccount', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得伺服器清單
 */
export const fetchServerList =  async():Promise<any> => {
  let response = await fetch(API_URL + '/getServerList', {
    method: 'POST',
    body: '',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得伺服器狀態
 */
export const fetchServerState =  async():Promise<any> => {
  let response = await fetch(API_URL + '/getServerState', {
    method: 'POST',
    body: '',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 建立伺服器主機
 * parameter: body
 */
 export const fetchCreateServer = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/createServer', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 更新伺服器主機
 * parameter: body
 */
 export const fetchUpdateServer = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/updateServer', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得合約清單
 *
 */
 export const fetchContractList = async ():Promise<any> => {
  let response = await fetch(API_URL + '/getContractsList', {
    method: 'POST',
    body: '',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 建立資訊合約
 * parameter: body
 */
 export const fetchCreateContract = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/createContract', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 更新資訊合約
 * parameter: body
 */
 export const fetchUpdateContract = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/updateContract', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}


/**
 * 取得人員每月工作時數清單
 *
 */
 export const fetchPersonsMonthHourList = async ():Promise<any> => {
  let response = await fetch(API_URL + '/getPersonsMonthHourList', {
    method: 'POST',
    body: '',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得Dashboard 資料
 *
 */
 export const fetchDashboardList = async ():Promise<any> => {
  let response = await fetch(API_URL + '/getDashboardList', {
    method: 'POST',
    body: '',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得取得各單位工時清單
 *
 */
 export const fetchUnitHourList = async ():Promise<any> => {
  let response = await fetch(API_URL + '/getUnitHourList', {
    method: 'POST',
    body: '',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得取得單位工時明細
 *
 */
 export const fetchUnitHourDetail = async (parameter:any):Promise<any> => {
  let response = await fetch(API_URL + '/getUnitHourDetail', {
    method: 'POST',
    body: JSON.stringify(parameter),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}

/**
 * 取得API伺服器版本資料
 *
 */
 export const fetchApiVersion = async ():Promise<any> => {
  let response = await fetch(API_URL + '/getApiVersion', {
    method: 'POST',
    body: '',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.accessToken
    }
  });
  return await response.json()
}



