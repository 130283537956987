import React, { useContext, useEffect, useState } from 'react';
import { NavLink as RRNavLink, withRouter, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Input, CustomInput } from 'reactstrap';
import Layout from '../components/Layout';
import styled from "styled-components";
import { ContractsContext, ContractsProvider } from 'contexts';
import { fetchContractList, fetchCreateContract, fetchUpdateContract } from '../contexts/fetchAction';

const DivModal = styled.div.attrs(() => {
})` 
  margin-bottom: 15px;
`;

const Table = styled.table.attrs(() => ({
  className: "table table-bordered"
}))` 
  width: 960px;
`;

/* 定義狀態樣式 */
const badgeColor = {
  primary: 'badge badge-primary',
  success: 'badge badge-success',
  warning: 'badge badge-warning',
  secondary: 'badge badge-secondary',
  danger: 'badge badge-danger',
}

/* 定義按鈕樣式 */
const color = {
  primary: 'btn-sm btn btn-primary',
  success: 'btn-sm btn btn-success',
  warning: 'btn-sm btn btn-warning',
  secondary: 'btn-sm btn btn-secondary'
}

// const Button = styled.button.attrs((props) => ({
//   className: props.className
// }))`  
// `;

const Contracts = () => {
  const { t } = useTranslation();
  const [ update, setUpdate ] = useState(false);
  const [ type, setType ] = useState("1");

  const changeType = async (event:any) => {
    setType(event.target.value);
  
  }

  return (  
    <ContractsProvider>
      <Layout>
        <h2>{t("contracts.title")}</h2>
        <hr />
        <Row>
        <Col lg={2}>
        <AddContract buttonLabel= "新增合約" className= "default-font" setUpdate= {setUpdate} update= {update} />        
        </Col>
        <Col lg={2}>
                <Input
                  id="searchType"
                  name="select"
                  type="select"
                  onChange={changeType}
                  defaultValue={type}
                >
                  <option value={"1"}>合約</option>
                  <option value={"2"}>網域</option>
                </Input>
        </Col>
      </Row>
        <List setUpdate= {setUpdate} update= {update} type= {type} />  
      </Layout>
    </ContractsProvider> 

  )
}

const List = (props:any) => {
  //由上層帶入資料
  const { update, setUpdate, type } = props;
  const { state, dispatch } = useContext(ContractsContext);
  const { contractsList } = state;

  const [ list , setList ] = useState([]);

  useEffect( () => {
    //取得合約清單
    const getContractList = async () => {
       const result = await fetchContractList();
       console.log(result);
       if(result.status === 200) {
          dispatch({ type: "GET_CONTRACT_LIST", payload: result.data });
          setList(result.data);

       }
    }
    getContractList();

  },[update,type]);

  return (
    <Table>
    <thead>
        <tr>
            <th scope="col">合約類型</th>
            <th scope="col">重新續約執行日</th>
            <th scope="col">合約名稱</th>
            <th scope="col">合約起始日</th>
            <th scope="col">合約到期日</th>
            <th scope="col">負責人</th>
            <th scope='col'>操作</th>
        </tr>
    </thead>
    <tbody>
    { list && list.map((i:any) => {
        if(i.contract_type == type)
        return(
            <tr key={i.contract_id}>
            <td>{i.contract_type === "1" ? "合約": "網域"}</td>
            <td>{i.contract_date}</td>
            <td>{i.contract_name}</td>
            <td>{i.start_date}</td>
            <td>{i.end_date}</td>
            <td>{i.contract_confirm}</td>
            <td>
              <Modify buttonLabel= "修改" className= "default-font" setUpdate= {setUpdate} update= {update} detail= {i} />
              </td>
            </tr>
        ) 
    }   
          )}
    </tbody>
    </Table>
  )
}

/**
 * 新增伺服器
 */ 
 const AddContract = (props:any) => {
  const {
    buttonLabel,
    className,   //由上層帶入class
    setUpdate,
    update
  } = props;

  const [modal, setModal] = useState(false);

  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [reDate, setReDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [owner, setOwner] = useState('');


  const toggle = () => {
    setModal(!modal);
    setType('');
    setName('');
    setReDate('');
    setStartDate('');
    setEndDate('');
    setOwner('');
  }
  
  // 加入新的伺服器主機
  const createContract = async (event:any) => {
    event.preventDefault();     
    const parameter = {
      'contract_type': type,
      'contract_name': name,
      'contract_date': reDate,
      'contract_start_date': startDate,
      'contract_end_date': endDate,
      'contract_confirm': owner
    }


      console.log(JSON.stringify(parameter));
      //呼叫API
      const result = await fetchCreateContract(parameter);
      if(result.status === 200) {
        alert(result.msg);
        setUpdate(!update);
        toggle();
      } else {
        alert(result.msg);
      }      
  }

  return (
    <DivModal>
      <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>新增伺服器</ModalHeader>
          <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="type" sm={3}>合約類型</Label>
              <Col sm={9}>
                <Input type="text" name="type" id="type" value= {type} onChange={(e) => setType(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="name" sm={3}>合約名稱</Label>
              <Col sm={9}>
                <Input type="text" name="name" id="name" value= {name} onChange={(e) => setName(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="reDate" sm={3}>續約執行日</Label>
              <Col sm={9}>
                <Input type="text" name="reDate" id="reDate" value= {reDate} onChange={(e) => setReDate(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="startDate" sm={3}>合約起始日</Label>
              <Col sm={9}>
                <Input type="text" name="startDate" id="startDate" value= {startDate} onChange={(e) => setStartDate(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="endDate" sm={3}>合約到期日</Label>
              <Col sm={9}>
                <Input type="text" name="endDate" id="endDate" value= {endDate} onChange={(e) => setEndDate(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="owner" sm={3}>負責人</Label>
              <Col sm={9}>
                <Input type="text" name="owner" id="owner" value= {owner} onChange={(e) => setOwner(e.target.value)} />
              </Col>
            </FormGroup>
          </Form>    
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={createContract}>確認</Button>{' '}
              <Button color="secondary" onClick={toggle}>取消</Button>
          </ModalFooter>
        </Modal>
    </DivModal>
  );
}

/**
 *  修改合約
 */ 
 const Modify = (props:any) => {
  const {
    buttonLabel,
    className,
    setUpdate,
    update,
    detail
  } = props;

  const [modal, setModal] = useState(false);
  
  const [id, setId] = useState(detail.contract_id);
  const [type, setType] = useState(detail.contract_type);
  const [name, setName] = useState(detail.contract_name);
  const [reDate, setReDate] = useState(detail.contract_date);
  const [startDate, setStartDate] = useState(detail.start_date);
  const [endDate, setEndDate] = useState(detail.end_date);
  const [owner, setOwner] = useState(detail.contract_confirm);

  useEffect( () => {
  },[update]);

  const toggle = () => { 
    setModal(!modal);
    //當關閉修改對話框時，將資料回復到上層帶入的資料
    setType(detail.contract_type);
    setName(detail.contract_name);
    setReDate(detail.contract_date);
    setStartDate(detail.start_date);
    setEndDate(detail.end_date);
    setOwner(detail.contract_confirm); 
  };

  //更新資訊合約
  const updateContract = async (event:any) => {
    event.preventDefault();     
    const parameter = {
        'contract_id': id,
        'contract_type': type,
        'contract_name': name,
        'contract_date': reDate,
        'contract_start_date': startDate,
        'contract_end_date': endDate,
        'contract_confirm': owner
      }

      // console.log(JSON.stringify(parameter));
      //呼叫API
      const result = await fetchUpdateContract(parameter);
      if(result.status === 200) {
        setUpdate(!update);
        alert(result.msg);
        toggle();
      } else {
        alert(result.msg);
      }      
  }

  return (
    <div className="float-right">
      <Button size="sm" color="primary" onClick={toggle}>{buttonLabel}</Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>修改合約</ModalHeader>
          <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="type" sm={3}>合約類型</Label>
              <Col sm={9}>
                <Input type="text" name="type" id="type" value= {type} onChange={(e) => setType(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="name" sm={3}>合約名稱</Label>
              <Col sm={9}>
                <Input type="text" name="name" id="name" value= {name} onChange={(e) => setName(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="reDate" sm={3}>續約執行日</Label>
              <Col sm={9}>
                <Input type="text" name="reDate" id="reDate" value= {reDate} onChange={(e) => setReDate(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="startDate" sm={3}>合約起始日</Label>
              <Col sm={9}>
                <Input type="text" name="startDate" id="startDate" value= {startDate} onChange={(e) => setStartDate(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="endDate" sm={3}>合約到期日</Label>
              <Col sm={9}>
                <Input type="text" name="endDate" id="endDate" value= {endDate} onChange={(e) => setEndDate(e.target.value)} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="owner" sm={3}>負責人</Label>
              <Col sm={9}>
                <Input type="text" name="owner" id="owner" value= {owner} onChange={(e) => setOwner(e.target.value)} />
              </Col>
            </FormGroup>
          </Form>  
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={updateContract}>確認</Button>{' '}
              <Button color="secondary" onClick={toggle}>取消</Button>
          </ModalFooter>
        </Modal>
    </div>
  );
}

export default withRouter(Contracts);

