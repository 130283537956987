import { NavLink as RRNavLink, withRouter, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import styled from "styled-components";

const Permissions = () => {
  const { t } = useTranslation();

  return (    
    <Layout>
      <h2>{t("permissions.title")}</h2>
      <hr />
    </Layout>
  )
}

export default withRouter(Permissions);